import Button from "../components/Button";
import { CloseSection, NpsForm } from "./styles";
import appService from "../../../../../controllers/app/appService";
import { useState } from "react";
import icons from "../../../../assets/icons";

interface Props {
  show: boolean;
  handleClose: () => void;
}

const NpsCard = ({ show, handleClose }: Props) => {
  const [rate, setRate] = useState<number>(-1);
  const [alert, setAlert] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const handleClick = (e: any) => {
    setRate(e.target.value);
    handleAlert(false);
  };
  const handleAlert = (value: boolean) => {
    setAlert(value);
  };

  const onSubmit = () => {
    if (rate == -1) {
      handleAlert(true);
    } else {
      appService.saveNpsReview(rate, comment);
      appService.incrementShowNps(true);
      handleClose();
    }
  };

  return (
    <>
      <CloseSection
        {...{ handleClose, show }}
        onClick={() => {
          handleClose();
          appService.incrementShowNps(false);
        }}
        style={{
          display: show ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 1,
        }}
      />
      <NpsForm
        style={{
          display: show ? "flex" : "none",
        }}
      >
        <h1>Deixe um comentário</h1>
        <span>Estamos em constante aperfeiçoamento</span>

        <ul>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={0}
              id="0"
            />
            <label
              htmlFor="0"
              style={{ backgroundColor: "rgba(238, 53, 52, 1)" }}
            >
              0
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={1}
              id="1"
            />
            <label
              htmlFor="1"
              style={{ backgroundColor: "rgba(246, 70, 70, 1)" }}
            >
              1
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={2}
              id="2"
            />
            <label
              htmlFor="2"
              style={{ backgroundColor: "rgba(254, 101, 79, 1)" }}
            >
              2
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={3}
              id="3"
            />
            <label
              htmlFor="3"
              style={{ backgroundColor: "rgba(255, 137, 67, 1)" }}
            >
              3
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={4}
              id="4"
            />
            <label
              htmlFor="4"
              style={{ backgroundColor: "rgba(253, 168, 59, 1)" }}
            >
              4
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={5}
              id="5"
            />
            <label
              htmlFor="5"
              style={{ backgroundColor: "rgba(255, 193, 32, 1)" }}
            >
              5
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={6}
              id="6"
            />
            <label
              htmlFor="6"
              style={{ backgroundColor: "rgba(235, 189, 15, 1)" }}
            >
              6
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={7}
              id="7"
            />
            <label
              htmlFor="7"
              style={{ backgroundColor: "rgba(192, 203, 15, 1)" }}
            >
              7
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={8}
              id="8"
            />
            <label
              htmlFor="8"
              style={{ backgroundColor: "rgba(157, 205, 6, 1)" }}
            >
              8
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={9}
              id="9"
            />
            <label
              htmlFor="9"
              style={{ backgroundColor: "rgba(121, 204, 1, 1)" }}
            >
              9
            </label>
          </li>
          <li>
            <input
              type="radio"
              onClick={(e) => {
                handleClick(e);
              }}
              name="rating"
              defaultValue={10}
              id="10"
            />
            <label
              htmlFor="10"
              style={{ backgroundColor: "rgba(47, 177, 2, 1)" }}
            >
              10
            </label>
          </li>
        </ul>

        <textarea
          onChange={(e) => setComment(e.target.value)}
          name="textarea"
          id=""
          placeholder="Comentário:"
        />

        <span className="alert" style={{ display: alert ? "block" : "none" }}>
          Considere atribuir uma nota antes de enviar!
        </span>

        <Button
          functionRef={onSubmit}
          title="Enviar"
          color="#ffffff"
          backgroundColor="rgba(46, 52, 64, 1)"
        />
        <div
          onClick={() => {
            handleClose();
            appService.incrementShowNps(false);
          }}
        >
          <icons.Close />
        </div>
      </NpsForm>
    </>
  );
};

export default NpsCard;

import styled from "styled-components";

export const InputBox = styled.input`
  height: 50px;
  width: 100%;
  background: rgba(0, 147, 221, 0.8);
  color: white;

  padding: 5px;
  margin: auto 5px;
  border-radius: 10px;
  border: solid 1px #000;

  cursor: pointer;

  font-weight: "bold";
`;

export const InfoCard = styled.ul`
  margin: 10px 5px 10px 5px;

  li {
    padding: 5px;
    border-radius: 5px;
    margin: 5px 0px;
    background-color: rgba(0, 147, 221, 0.8);
    color: #fff;
  }
`;

export const Box = styled.div`
  display: flex;
`;

class Icons {
  Help = () => (
    <svg strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
    </svg>
  );

  Coments = () => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"></path>
    </svg>
  );

  Menu = () => {
    return (
      <svg viewBox="0 0 100 80" width="40" height="40">
        <rect width="100" height="15"></rect>
        <rect y="30" width="100" height="15"></rect>
        <rect y="60" width="100" height="15"></rect>
      </svg>
    );
  };

  Close = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
      </svg>
    );
  };

  Account = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M16 7.992C16 3.58 12.416 0 8 0S0 3.58 0 7.992c0 2.43 1.104 4.62 2.832 6.09.016.016.032.016.032.032.144.112.288.224.448.336.08.048.144.111.224.175A7.98 7.98 0 0 0 8.016 16a7.98 7.98 0 0 0 4.48-1.375c.08-.048.144-.111.224-.16.144-.111.304-.223.448-.335.016-.016.032-.016.032-.032 1.696-1.487 2.8-3.676 2.8-6.106zm-8 7.001c-1.504 0-2.88-.48-4.016-1.279.016-.128.048-.255.08-.383a4.17 4.17 0 0 1 .416-.991c.176-.304.384-.576.64-.816.24-.24.528-.463.816-.639.304-.176.624-.304.976-.4A4.15 4.15 0 0 1 8 10.342a4.185 4.185 0 0 1 2.928 1.166c.368.368.656.8.864 1.295.112.288.192.592.24.911A7.03 7.03 0 0 1 8 14.993zm-2.448-7.4a2.49 2.49 0 0 1-.208-1.024c0-.351.064-.703.208-1.023.144-.32.336-.607.576-.847.24-.24.528-.431.848-.575.32-.144.672-.208 1.024-.208.368 0 .704.064 1.024.208.32.144.608.336.848.575.24.24.432.528.576.847.144.32.208.672.208 1.023 0 .368-.064.704-.208 1.023a2.84 2.84 0 0 1-.576.848 2.84 2.84 0 0 1-.848.575 2.715 2.715 0 0 1-2.064 0 2.84 2.84 0 0 1-.848-.575 2.526 2.526 0 0 1-.56-.848zm7.424 5.306c0-.032-.016-.048-.016-.08a5.22 5.22 0 0 0-.688-1.406 4.883 4.883 0 0 0-1.088-1.135 5.207 5.207 0 0 0-1.04-.608 2.82 2.82 0 0 0 .464-.383 4.2 4.2 0 0 0 .624-.784 3.624 3.624 0 0 0 .528-1.934 3.71 3.71 0 0 0-.288-1.47 3.799 3.799 0 0 0-.816-1.199 3.845 3.845 0 0 0-1.2-.8 3.72 3.72 0 0 0-1.472-.287 3.72 3.72 0 0 0-1.472.288 3.631 3.631 0 0 0-1.2.815 3.84 3.84 0 0 0-.8 1.199 3.71 3.71 0 0 0-.288 1.47c0 .352.048.688.144 1.007.096.336.224.64.4.927.16.288.384.544.624.784.144.144.304.271.48.383a5.12 5.12 0 0 0-1.04.624c-.416.32-.784.703-1.088 1.119a4.999 4.999 0 0 0-.688 1.406c-.016.032-.016.064-.016.08C1.776 11.636.992 9.91.992 7.992.992 4.14 4.144.991 8 .991s7.008 3.149 7.008 7.001a6.96 6.96 0 0 1-2.032 4.907z" />
      </svg>
    );
  };

  IzzistritButton = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420.35 418.91">
        <defs>
          <style>.cls-1fill:#0093dd;.cls-2fill:#54dd82;</style>
        </defs>
        <title>Botão Izzistrit principal</title>
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <circle className="cls-1" cx="210.18" cy="210.17" r="66.79" />
            <path
              className="cls-2"
              d="M234.82,418.91l-2-17.49a191.89,191.89,0,0,0,45.1-10.92L284.07,407A209.85,209.85,0,0,1,234.82,418.91Zm-50.61-.16a210,210,0,0,1-49.15-12.23l6.29-16.45a191.67,191.67,0,0,0,45,11.2ZM329,383.55,319,369a193.57,193.57,0,0,0,34.83-30.67L367,350.1A211.42,211.42,0,0,1,329,383.55Zm-238.68-.72a211.21,211.21,0,0,1-37.79-33.67l13.19-11.65a194.24,194.24,0,0,0,34.66,30.88Zm305.63-74.26-15.55-8.26A191.55,191.55,0,0,0,397,257l17.08,4.27A209.43,209.43,0,0,1,395.94,308.57ZM23.8,307.42A209.63,209.63,0,0,1,5.94,260l17.11-4.16a191.37,191.37,0,0,0,16.36,43.42ZM420.35,211h-17.6v-.83a193.81,193.81,0,0,0-5.54-46.07l17.09-4.2a210.85,210.85,0,0,1,6.05,50.27ZM17.6,209.76l-17.6,0a210.79,210.79,0,0,1,6.15-50.26l17.09,4.24A193.21,193.21,0,0,0,17.6,209.76Zm363.15-89a193.13,193.13,0,0,0-26.37-38.18l13.17-11.67a210.6,210.6,0,0,1,28.79,41.66Zm-341-.34-15.57-8.23A210.72,210.72,0,0,1,53.07,70.55l13.15,11.7A193.15,193.15,0,0,0,39.78,120.37ZM319.66,51.72a192.89,192.89,0,0,0-41-21.61l6.26-16.45a210.62,210.62,0,0,1,44.79,23.58ZM101,51.51,91,37A209.85,209.85,0,0,1,135.87,13.5L142.09,30A192.7,192.7,0,0,0,101,51.51ZM233.6,19a196,196,0,0,0-46.43,0L185.1,1.47a214.28,214.28,0,0,1,50.62.06Z"
            />
            <path
              className="cls-2"
              d="M210.18,64A146.18,146.18,0,1,0,356.36,210.17,146.17,146.17,0,0,0,210.18,64Zm0,266A119.79,119.79,0,1,1,330,210.17,119.79,119.79,0,0,1,210.18,330Z"
            />
          </g>
        </g>
      </svg>
    );
  };

  IzzistritButton2 = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 420.35 418.91"
        style={{ width: "30px", height: "30px" }}
      >
        <defs>
          <style>.cls-1fill:#fff;.cls-2fill:#54dd82;</style>
        </defs>
        <title>Botão Izzistrit secundário</title>
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <circle className="cls-1" cx="210.18" cy="210.17" r="66.79" />
            <path
              className="cls-2"
              d="M234.82,418.91l-2-17.49a191.89,191.89,0,0,0,45.1-10.92L284.07,407A209.85,209.85,0,0,1,234.82,418.91Zm-50.61-.16a210,210,0,0,1-49.15-12.23l6.29-16.45a191.67,191.67,0,0,0,45,11.2ZM329,383.55,319,369a193.57,193.57,0,0,0,34.83-30.67L367,350.1A211.42,211.42,0,0,1,329,383.55Zm-238.68-.72a211.21,211.21,0,0,1-37.79-33.67l13.19-11.65a194.24,194.24,0,0,0,34.66,30.88Zm305.63-74.26-15.55-8.26A191.55,191.55,0,0,0,397,257l17.08,4.27A209.43,209.43,0,0,1,395.94,308.57ZM23.8,307.42A209.63,209.63,0,0,1,5.94,260l17.11-4.16a191.37,191.37,0,0,0,16.36,43.42ZM420.35,211h-17.6v-.83a193.81,193.81,0,0,0-5.54-46.07l17.09-4.2a210.85,210.85,0,0,1,6.05,50.27ZM17.6,209.76l-17.6,0a210.79,210.79,0,0,1,6.15-50.26l17.09,4.24A193.21,193.21,0,0,0,17.6,209.76Zm363.15-89a193.13,193.13,0,0,0-26.37-38.18l13.17-11.67a210.6,210.6,0,0,1,28.79,41.66Zm-341-.34-15.57-8.23A210.72,210.72,0,0,1,53.07,70.55l13.15,11.7A193.15,193.15,0,0,0,39.78,120.37ZM319.66,51.72a192.89,192.89,0,0,0-41-21.61l6.26-16.45a210.62,210.62,0,0,1,44.79,23.58ZM101,51.51,91,37A209.85,209.85,0,0,1,135.87,13.5L142.09,30A192.7,192.7,0,0,0,101,51.51ZM233.6,19a196,196,0,0,0-46.43,0L185.1,1.47a214.28,214.28,0,0,1,50.62.06Z"
            />
            <path
              className="cls-2"
              d="M210.18,64A146.18,146.18,0,1,0,356.36,210.17,146.17,146.17,0,0,0,210.18,64Zm0,266A119.79,119.79,0,1,1,330,210.17,119.79,119.79,0,0,1,210.18,330Z"
            />
          </g>
        </g>
      </svg>
    );
  };
}

export default new Icons();

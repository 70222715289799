import { toast } from "react-toastify";
import { ChatbotStateProps } from "../../config/interfaces";
import api from "../../services/api";

type StateProps = {
  previous: number;
  current: number;
  rated: boolean;
};
class Services {
  showNpsComponent = () => {
    let current = this.getShowNps().current;
    let previous = this.getShowNps().previous;
    let rated = this.getShowNps().rated;
    if (current >= previous || !rated) {
      return true;
    } else {
      return false;
    }
  };
  incrementShowNps = (param: boolean) => {
    let current = this.getShowNps().current;
    let previous = this.getShowNps().previous;
    let rated = this.getShowNps().rated;
    let state: StateProps = {
      previous: rated && current >= previous ? current + 1 : previous,
      current: rated ? (current >= previous ? 0 : current + 1) : current,
      rated: param,
    };
    sessionStorage.setItem("nps_review", JSON.stringify(state));
    return state;
  };

  getShowNps = () => {
    let state =
      sessionStorage.getItem("nps_review") ||
      `{ "previous": 1, "current": 0, "rated": true }`;

    let parsed: StateProps = JSON.parse(state);
    return parsed;
  };

  saveNpsReview = async (value: number, description: string) => {
    let params = { value: value, description: description };
    try {
      await toast.promise(api.post("/nps", params), {
        pending: "Enviando avaliação...",
        success: {
          render() {
            return "Obrigado por avaliar!";
          },
          autoClose: 1500,
        },
        error: "Falha ao submeter avaliação.",
      });
    } catch (error) {
      return error;
    }
  };
}

export default new Services();

import styled from "styled-components";

export const ContainerModal = styled.div`
  display: flex;
  position: absolute;
  top: 7vh;
  right: 15vw;
  bottom: 10px;
  left: 15vw;
  padding: 40px;
  box-shadow: 10px 20px 40px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    fill: #5a5a5a;
    cursor: pointer;

    :hover {
      fill: rgba(255, 0, 0, 1);
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 25px;
    left: 5vw;
    right: 5vw;
    bottom: auto;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;

    svg {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 15px;
      height: 15px;
    }

    .login-social {
      p {
        text-align: center;
      }
    }
  }
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 55%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
    img {
      width: 90%;
    }
  }
`;

export const ModalProfile = styled.div`
  z-index: 1005;
  position: absolute;
  top: 60px;
  right: 10px;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  border: 1.5px solid #0093dd;
  background: rgba(255, 255, 255, 1);

  .basic-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img-profile {
      width: 70px;
      border-radius: 50%;
    }

    .username {
      color: rgba(2, 113, 168, 1);

      font-weight: bold;
      font-size: large;
      font-family: "DM Sans", sans-serif;
    }
  }

  .info {
    display: grid;
    justify-content: left;
    margin-top: 20px;

    .item {
      text-align: left;
      margin: 5px 0px 5px 0px;
      font-weight: 500;
      color: rgba(2, 113, 168, 1);
      font-size: 14px;

      label {
        font-family: "DM Sans", sans-serif;
      }

      strong {
        font-size: 16px;
        font-family: "DM Sans", sans-serif;
      }
    }
  }

  .arrow {
    width: 15px;
    position: absolute;
    top: -12.5px;
    right: 10px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }

  .logout {
    max-width: 80px;
    margin: 0px 5px 5px 0px;
    border-radius: 10px;
    font-family: "DM Sans", sans-serif;
    background: rgba(0, 147, 221, 1);
  }

  .logout:hover {
    background-color: rgba(0, 147, 221, 0.8);
  }

  .editar {
    max-width: 80px;
    margin: 0px 0px 5px 5px;
    border-radius: 10px;
    border: solid 1px rgba(0, 147, 221, 1);
    font-family: "DM Sans", sans-serif;
    color: rgba(0, 147, 221, 1);
    background: transparent;
  }

  .editar:hover {
    background-color: rgba(0, 147, 221, 0.1);
  }

  @media screen and (max-width: 768px) {
    width: auto;
    left: 5vw;
    right: 5vw;

    .username {
      max-width: 200px;
      text-align: center;
      font-weight: bold;
    }

    .info {
      padding-top: 10px;
    }

    .logout {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .editar {
      margin-bottom: 10px;
      margin-left: 10px;
    }

    .arrow {
      right: 50%;
    }
  }
`;

export const ModalEditProfile = styled.div`
  z-index: 1005;
  position: absolute;
  top: 60px;
  right: 10px;
  width: 40%;
  padding: 10px;
  border-radius: 10px;
  border: 1.5px solid #0093dd;
  background: rgba(255, 255, 255, 1);

  p {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    color: rgba(2, 113, 168, 1);
  }

  .basic-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img-profile {
      width: 70px;
      border-radius: 50%;
    }

    .username {
      color: rgba(2, 113, 168, 1);

      font-weight: bold;
      font-size: large;
      font-family: "DM Sans", sans-serif;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: left;

    margin-top: 20px;

    label {
      font-family: "DM Sans", sans-serif;
      color: rgba(2, 113, 168, 1);
    }

    strong {
      font-size: 16px;
      font-family: "DM Sans", sans-serif;
      color: rgba(2, 113, 168, 1);
    }

    select,
    input[type="text"] {
      width: 90%;
      height: 40px;
      margin: 5px 0px 10px 0px;
      padding: 0px 5px;
      border: none;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      color: #2a2727;
      background-color: rgba(216, 222, 233, 1);
    }

    input[type="file"] {
      width: 90%;
      margin: 5px 0px 10px 0px;
      padding: 10px 5px;
      border: none;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      color: #2a2727;
      background-color: rgba(216, 222, 233, 1);
      cursor: pointer;
    }

    select {
      width: calc(90% + 10px);
      cursor: pointer;
    }
  }

  .arrow {
    width: 15px;
    position: absolute;
    top: -12.5px;
    right: 10px;
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
  }

  .save {
    max-width: 80px;
    margin: 0px 5px 5px 0px;
    border-radius: 10px;
    font-family: "DM Sans", sans-serif;
    background: rgba(0, 147, 221, 1);
  }

  .save:hover {
    background-color: rgba(0, 147, 221, 0.8);
  }

  .cancel {
    max-width: 80px;
    margin: 0px 0px 5px 5px;
    border-radius: 10px;
    border: solid 1px rgba(0, 147, 221, 1);
    font-family: "DM Sans", sans-serif;
    color: rgba(0, 147, 221, 1);
    background: transparent;
  }

  .cancel:hover {
    background-color: rgba(0, 147, 221, 0.1);
  }

  span {
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    color: rgba(2, 113, 168, 1);
  }

  @media screen and (max-width: 768px) {
    width: auto;
    left: 5vw;
    right: 5vw;

    .username {
      max-width: 200px;
      text-align: center;
      font-weight: bold;
    }

    .info {
      padding-top: 10px;

      input[type="text"],
      select {
        width: calc(100% - 10px);
        height: 40px;
        border: none;
        border-radius: 5px;
        outline: none;
      }
      input[type="file"] {
        width: calc(100% - 10px);
        font-size: 14px;
        padding: 10px 0px 10px 10px;
      }

      select {
        width: 100%;
      }
    }

    .buttons {
      margin: 20px 10px 10px 10px;
    }

    .arrow {
      right: 40%;
    }
  }
`;

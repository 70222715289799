import React from "react";
import { InputBox } from "./styles";
import InputRadio from "../../components/InputRadio";
import { ChatbotProps } from "../../../config/interfaces";
import { getAssistiveResource } from "../../../config/constants";
import { i18n } from "../../../translate/i18n";

const AssistiveResource = (props: ChatbotProps) => {

    function handleSubmit(label: string, param: string) {
        props.actionProvider.setAssistiveResource(param);
        props.actionProvider.setLastInteraction("assistiveResource");
        props.actionProvider.validationWidgetLauncher(`${label}, ${i18n.t('chatbot.widgets.submitConfirmation')}`);
    }

    return <>
        <InputBox>
            <form>
                {
                    getAssistiveResource(props.actionProvider.getDisabilityType()).map((element, index) => {
                        return (
                            <InputRadio
                                key={index}
                                submit={handleSubmit}
                                label={element.label}
                                value={element.value}
                                index={index}
                            />
                        );
                    })
                }
            </form>
        </InputBox>
    </>;
};

export default AssistiveResource;
import styled from "styled-components";

export const LoginForm = styled.div`
  display: flex;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 55%;

    h1 {
      font-family: "Poppins", sans-serif;
      line-height: 1;
      font-size: 40px;
      color: #2a2727;
    }

    form {
      margin-bottom: 10px;
      .subtitle {
        max-width: 90%;
        margin-bottom: 20px;
        font-weight: bold;
        font-family: "DM Sans", sans-serif;
        color: #5a5a5a;
      }

      span {
        display: flex;
        align-items: center;
        margin-left: 5px;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
        font-size: 14px;
        color: red;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }

      input[type="email"],
      input[type="password"] {
        width: 80%;
        height: 40px;
        margin: 5px 0px 10px 0px;
        padding: 0px 5px;
        border: none;
        border-radius: 5px;
        outline: none;
        font-size: 16px;
        font-family: "DM Sans", sans-serif;
        font-weight: bold;
        color: #2a2727;
        background-color: rgba(216, 222, 233, 1);
      }

      label {
        display: flex;
        align-items: center;
        font-family: "DM Sans", sans-serif;
      }

      button {
        padding: 10px 25px;
        border: none;
        border-radius: 10px;
        font-weight: bold;
        font-family: "DM Sans", sans-serif;
        color: #ffffff;
        background-color: rgba(46, 52, 64, 1);
        cursor: pointer;

        :hover {
          background-color: rgba(46, 52, 64, 0.9);
        }
      }

      .loginButtonArea {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
      }

      .register-link {
        text-decoration: underline;
        color: rgba(46, 52, 64, 1);
        font-family: "DM Sans", sans-serif;
        cursor: pointer;

        :hover {
          color: rgba(46, 52, 64, 0.8);
        }
      }
    }

    .loginSocial {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      p {
        font-family: "DM Sans", sans-serif;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 180px;
        width: fit-content;
        padding: 10px 10px;
        margin-bottom: 15px;
        margin-top: 5px;
        border: none;
        border-radius: 5px;
        font-weight: bold;
        font-family: "DM Sans", sans-serif;
        cursor: pointer;
      }

      .google-button {
        color: rgba(255, 75, 38, 0.8);
        background-color: rgba(216, 222, 233, 1);
        img {
          width: 15px;
          height: 15px;
          padding: 4px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: #ffffff;
        }

        :hover {
          background-color: rgba(216, 222, 233, 0.8);
        }
      }
    }

    .leave {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-top: 10px;
      font-size: 14px;

      input {
        margin-right: 5px;
        cursor: pointer;
      }

      label {
        cursor: pointer;
        color: #2a2727;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    .content {
      width: 100%;
      h1 {
        font-size: 20px;
        text-align: center;
      }

      form {
        width: 100%;
        .subtitle {
          max-width: 100%;
          margin: 10px 0px 10px 0px;
          font-size: 14px;
          text-align: center;
        }

        .loginButtonArea {
          width: 100%;
        }

        button {
          padding: 10px 15px;
          margin-top: 15px;
          width: fit-content;
          border: none;
          border-radius: 10px;
        }

        input[type="email"],
        input[type="Password"] {
          width: 100%;
          height: 40px;
          border: none;
          border-radius: 5px;
          outline: none;
        }
      }

      .loginSocial {
        align-items: center;
        font-size: 14px;
      }

      .leave {
        justify-content: center;
        font-size: 12px;
      }
    }
  }
`;

export const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 55%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
    img {
      width: 90%;
    }
  }
`;

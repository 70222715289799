const messages = {
  en: {
    translations: {
      modal: {
        initial: {
          title: "Accessible and Customized Routes",
          sub: "It's easy to use:",
          s1: "Choose origin and destination",
          s2: "Select the type of disability",
          s3: "Select the assistive resource",
          s4: "Choose the displacement mode",
          s5: "Generate the route",
        },
        location: {
          search: "Enter the location ",
          current: "Use current location",
          button: "Search",
        },
      },
      bar: {
        router: {
          from: "From: ",
          to: "To: ",
          disabilityTypeList: "Type of Disability",
          assistiveResource: "Assistive Resource",
          transport: "Transport",
          clearButtom: "Clear",
          goButtom: "Go",
        },
      },
      legend: {
        classification: "Classification",
        favourable: "Favourable",
        acceptable: "Acceptable",
        critical: "Critical",
        nps: {
          title: "Write your review",
          sub: "Tell us your experience on the Izzistrit platform",
          send: "Send",
          question:
            "On a scale of 0 to 10, how much would you indicate this platform to others?",
        },
      },
      consts: {
        transportList: {
          walking: "Walking",
          driving: "Driving",
          bike: "Bike",
          public: "Public",
          taxi: "Taxi",
          app: "Car App",
        },
        disabilityTypeList: {
          cognitive: "Cognitive",
          hearing: "Hearing",
          physical: "Physical",
          visual: "Visual",
          other: "I don't have",
        },
        assistiveResourceList: {
          crutch: "Crutch",
          guideDog: "Guide Dog",
          hearingAid: "Hearing Aid",
          llp: "Lower Limb Prosthesis",
          ulp: "Upper Limb Prosthesis",
          walker: "Walker",
          walkingStick: "Walking stick",
          wheelchair: "Wheelchair",
          other: "I don't use",
        },
        tourConfig: {
          from: "Choose origin",
          to: "Choose destination",
          disabilityType: "Select the type of disability",
          assistiveResource: "Select the Assistive Feature",
          transport: "Select the means of transport",
          goButtom: "Generate the route",
          nextButton: "Advance",
          prevButton: "Return",
          welcome: "Hi, I'm Izzi and I'm going to show you how easy it is to use our platform",
          chatbot: "If you prefer, you can call me here"
        },
      },
      httpResponses: {
        searching: {
          in: "Searching for locations",
          sucess: "Locations successfully obtained",
          fail: {
            general: "Failed to search for locations",
            nodata: "No matching location",
          },
        },
        getrouter: {
          in: "Getting better route",
          sucess: "Route successfully obtained",
          fail: {
            general: "Failed to get route",
          },
        },
        nps: {
          in: "Sending review",
          sucess: "Thanks for the review!",
          fail: {
            general: "Failed to submit",
          },
        },
      },
      chatbot: {
        getAccess: 'Click here to access the chatbot!',
        conective: 'with',
        type: 'Type here.',
        actionProvider: {
          fromToAddresses: {
            requests: {
              from: "Where do you want to go out? ",
              to: "Where are you going? "
            },
            foundEmpty: "I didn't find any results for this search",
            foundSomething: "Select the address below: "
          },
          disabilityType: "What kind of deficiency?", 
          assistiveResource: "What's the assistive resource?",
          transport: "How do you want to go?",
          options: 'Select an option: ',
          startMessage: "We are going to generate a new custom route! Click to start!",
          notFound: 'Please, type again',
          defaultMessage: "My answers are limited. Enter the suggested options.",
          generateRoute: "Your route information has been displayed on the map! Stay tuned!",
        },
        configMessages: {
          createChat: "Hi, I'm Izzi and I'll indicate the best route for you",
          startCreateChat: "Click start to continue and generate your route!"
        },
        widgets: {
          start: 'Start',
          submitConfirmation: 'is it right?',
          sure: 'are you sure?',
          tryAgain: 'Try again',
          confirm: {
            yes: 'Yes',
            no: 'No',
          },
          confirmRoute: {
            confirm: 'Confirm',
            return: 'Return',
            origin: 'Origin:',
            destination: 'Destination:',
            disabilityType: 'Disability type:',
            assistiveResource: 'Assistive resource:',
            transport: 'Transport:'
          },
          placesMessages: {
            isThere: "That's the correct place?",
            didntFound: "Address not listed",
            destiny: "Is that your destiny?"
          },
          generateRoute: {
            anotherRoute: 'Generate another route',
            anotherOption: 'Another option',
          }
        }
      },
    },
  },
};

export { messages };

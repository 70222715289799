import { MutableRefObject } from "react";
import { Map as MapType } from "leaflet";
import ReactDOMServer from "react-dom/server";
import { MapProps } from "../../config/interfaces";
import { MapStyle, LocaleControlStyle } from "./mapStyles";
import { MapContainer, TileLayer, GeoJSON, useMapEvent } from "react-leaflet";
// eslint-disable-next-line
import "leaflet/dist/leaflet.css";
import icons from "../assets/map";
import L from "leaflet";
import { Feature, GeoJsonObject } from "geojson";

export default function Map({
  mapState,
  setmapState,
  routerLayer,
  mapRef,
}: MapProps) {
  const go_to_locale = () => {
    mapRef.current?.locate({ setView: true });
  };

  const getIcon = (feature: Feature) => {
    var EquipamentIcon = icons.getIcon(feature.properties?.type || "");

    const iconPerson = L.divIcon({
      className: "custom icon",
      html: ReactDOMServer.renderToString(
        <EquipamentIcon properties={feature.properties} />
      ),
      iconAnchor: [12, 0],
    });

    return iconPerson;
  };

  return (
    <MapStyle>
      <MapContainer
        center={{ lat: -15.783589, lng: -47.915246 }}
        zoom={4}
        ref={mapRef}
        minZoom={3}
        maxBounds={[
          //south west
          [-180.0, 180.0],
          //north east
          [180.0, -180.0],
        ]}
        maxBoundsViscosity={1.0}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON
          data={
            {
              type: "FeatureCollection",
              features: [],
            } as GeoJsonObject
          }
          ref={routerLayer}
          pointToLayer={function (geoJsonPoint, latlng) {
            let geoPoint = L.marker(latlng, { icon: getIcon(geoJsonPoint) });
            return geoPoint
              .bindPopup(
                `<p style="text-align: center">
                  <strong>
                    ${geoJsonPoint.properties.type}
                  </strong>
                  </br>
                    Classificação: ${geoJsonPoint.properties.label}
                </p>
                `
              )
              .on("mouseover", (e) => {
                geoPoint.openPopup();
              })
              .on("mouseout", (e) => {
                geoPoint.closePopup();
              });
          }}
        />
        <LocaleControl position="topleft" onClick={go_to_locale} map={mapRef} />
      </MapContainer>
    </MapStyle>
  );
}

function LocaleControl({
  position,
  onClick,
  map,
}: {
  position: string;
  map: MutableRefObject<MapType | null>;
  onClick: () => void;
}) {
  useMapEvent("locationfound", (location) => {
    const latlng = location.latlng;
    const radius = location.accuracy;
    const circle = L.circle(latlng, radius);

    var greenIcon = icons.BlueMarker;

    const maker = L.marker(latlng, { icon: greenIcon() });

    map.current && circle.addTo(map.current);
    map.current && maker.addTo(map.current);

    setTimeout(function () {
      map.current && circle.removeFrom(map.current);
      map.current && maker.removeFrom(map.current);
    }, 10000);
  });

  const POSITION_CLASSES: { [id: string]: string } = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
  };

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <>
      <LocaleControlStyle className={positionClass}>
        <div className="leaflet-bar leaflet-control" onClick={onClick}>
          <icons.NearToMe />
        </div>
      </LocaleControlStyle>
    </>
  );
}

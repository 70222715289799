import { tourBallonProps } from "../../../config/interfaces"
import "./styles.css";

const TourBallon = ({message}:tourBallonProps) => {
    return(
        <div 
            className="tour-ballon-container"
        >
            <p>{message}</p>
        </div>
    )
}

export default TourBallon
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { LoginForm, LogoSection } from "./styles";
import { InputLoginProps } from "../../../../../../config/interfaces";
import { OverridableTokenClientConfig } from "@react-oauth/google";
import Button from "../Button";

interface LoginFormCardProps {
  loginForm: UseFormReturn<InputLoginProps>;
  onSubmitLogin: SubmitHandler<InputLoginProps>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  googleLogin: (
    overrideConfig?: OverridableTokenClientConfig | undefined
  ) => void;
  handleClose: Function;
}

const LoginFormCard = (props: LoginFormCardProps) => {
  return (
    <LoginForm>
      <div className="content">
        <h1>Já faz parte da nossa rede?</h1>
        <form onSubmit={props.loginForm.handleSubmit(props.onSubmitLogin)}>
          <p className="subtitle">
            Entre com sua conta e tenha melhor experiência com as rotas
            personalizadas!
          </p>

          <label>
            EMAIL
            {props.loginForm.formState.errors.email && (
              <>
                <span>
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.loginForm.register("email", { required: true })}
            type="email"
            name="email"
            id="email"
          />
          <br />
          <label>
            SENHA
            {props.loginForm.formState.errors.password && (
              <>
                <span>
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.loginForm.register("password", { required: true })}
            type="password"
            id="password"
          />
          <br />

          <div className="loginButtonArea">
            <button>Entrar</button>
            <p
              className="register-link"
              onClick={() => props.setIsLogin(false)}
            >
              Cadastrar-se
            </p>
          </div>
        </form>

        <div className="loginSocial">
          <p>SE PREFERIR</p>

          <Button
            title={"Entrar com Google"}
            image={"/icons/google-icon.svg"}
            functionRef={props.googleLogin}
            backgroundColor="rgba(216, 222, 233, 1)"
            color="rgba(255, 75, 38, 0.8)"
          />
        </div>

        <div className="leave">
          <input
            onChange={() => props.handleClose()}
            type="radio"
            name="radio"
            id="radio"
          />
          <label htmlFor="radio">Não, obrigado</label>
        </div>
      </div>

      <LogoSection>
        <img
          className="logotipo"
          src={"/images/logotipo.png"}
          alt="Logotipo do Izzistrit"
        />
      </LogoSection>
    </LoginForm>
  );
};

export default LoginFormCard;

import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import Icons from "../assets/icons";
import auth from "../../controllers/user/auth";
import checkpoints from "../../controllers/map/checkpoints";

import UserModal from "../components/modais/user/modal";
import { Button } from "../components/buttons/buttonStyles";
import { BarStyle, Global, LocationForm } from "./barStyles";
import CustomSelectComponent from "../components/inputs/CustomSelect";
import NpsCard from "../components/modais/user/NpsCard";

import {
  AssistiveResourceProps,
  BarProps,
  GetRouteProps,
  AddressProps,
  ChildComponentRef,
} from "../../config/interfaces";
import {
  transportList,
  disabilityTypeList,
  handleDisabilityType,
  getAssistiveResource,
  handleLinkedAssistiveResource,
  handleScreenSize,
  getDisabilityTypeLabel,
  getAssistiveResourceLabel,
} from "../../config/constants";
import { CloseSection } from "../components/modais/main/modalStyles";

import { i18n } from "../../translate/i18n";
import SelectizeComponent from "../components/inputs/Selectize";
import appService from "../../controllers/app/appService";
import axios from "axios";
import { toast } from "react-toastify";

export function Bar({
  showNps,
  setShowNps,
  mapState,
  setRouter,
  addRouteMakers,
  resetMap,
  showModal,
  setShowModal,
  ...params
}: BarProps) {
  const userData = auth.getUser();

  const [showProfile, setShowProfile] = useState(false);
  const handleShowNps = () => {
    setShowNps(!showNps);
  };

  const handleSetFirstAccess = () => {
    setTimeout(() => setShowModal(true), 15000);

    setTimeout(() => {
      !auth.getDataStorage() && auth.setDataStore(true);
    }, 17000);
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowProfile = () => {
    setShowProfile(!showProfile);
  };

  const [userCoords, setUserCoords] = useState({
    userLatitude: 0,
    userLongitude: 0,
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      from: "",
      to: "",
      from_cords: "",
      to_cords: "",
      transport: "",
      transport_label: "",
      disability_type: userData?.disability_type,
      assistive_resource: userData?.assistive_resource,
      disability_type_label: `${
        auth.isAuthenticated()
          ? getDisabilityTypeLabel(userData?.disability_type)
          : i18n.t("bar.router.disabilityTypeList")
      }`,
      assistive_resource_label: `${
        auth.isAuthenticated()
          ? getAssistiveResourceLabel(userData?.assistive_resource)
          : i18n.t("bar.router.assistiveResource")
      }`,
    },
  });

  /**
   * @param data Dados para gerar a rota personalizada
   */
  async function onSubmit(data: GetRouteProps) {
    handleSetFirstAccess();

    setRouter(await checkpoints.getRoute(data));

    if (appService.getShowNps().previous > 0) {
      if (appService.getShowNps().rated) {
        appService.incrementShowNps(true);
      }

      if (appService.showNpsComponent()) {
        setTimeout(() => {
          setShowNps(true && auth.isAuthenticated());
        }, 15000);
      }
    }
  }

  /**Exibir e ocultar campos de pesquisa em modo mobile */
  const [showFields, setShowFields] = useState(true);
  function handleShowSearchMenu() {
    setShowFields(!showFields);
  }

  const [disabilityType, setDisabilityType] = useState<string[]>([]);
  /**
   * @param type tipo de deficiência marcado ou desmarcado
   * @param checked Estado do campo (marcado ou desmarcado)
   */
  function handleDisabilityTypeLocal(type: string, checked: boolean) {
    handleDisabilityType(type, checked, setDisabilityType, disabilityType);
  }

  const [linkedAssistiveResource, setLinkedAssistiveResource] = useState<
    AssistiveResourceProps[]
  >([]);
  const [noFilteredState, setNoFilteredState] = useState<
    AssistiveResourceProps[]
  >([]);

  /**
   *
   * @param type tipo de deficiência marcado ou desmarcado
   * @param checked Estado do campo (marcado ou desmarcado)
   */

  function handleLinkedAssistiveResourceLocal(type: string, checked: boolean) {
    handleLinkedAssistiveResource(
      type,
      checked,
      linkedAssistiveResource,
      setLinkedAssistiveResource,
      noFilteredState,
      setNoFilteredState
    );
  }

  const [keepingLinkedAssistive, setKeepingLinkedAssistive] = useState<
    AssistiveResourceProps[]
  >([]);

  /**
   *
   * @param type tipo de deficiência marcado ou desmarcado
   */

  function handleLinkedAssistiveResourceFilter(type: string) {
    setKeepingLinkedAssistive(getAssistiveResource(type));
    return getAssistiveResource(type);
  }

  function go_to_location(lat: Number, lng: Number, map: any) {
    if (map?.map) {
      return map.map.flyTo([lat, lng], 16);
    }
  }

  useEffect(() => {
    register("from", { required: true });
    register("from_cords");
    register("to", { required: true });
    register("to_cords");
    register("disability_type");
    register("assistive_resource");

    navigator.geolocation.getCurrentPosition(function (location) {
      setUserCoords({
        userLatitude: location.coords.latitude,
        userLongitude: location.coords.longitude,
      });
    });
  }, []);

  const fromChildrenInput = useRef<ChildComponentRef>(null);
  const toChildrenInput = useRef<ChildComponentRef>(null);
  const disabilityTypeChildrenInput = useRef<ChildComponentRef>(null);
  const assistiveResourceChildrenInput = useRef<ChildComponentRef>(null);
  const transpotChildrenInput = useRef<ChildComponentRef>(null);

  const resetRouter = () => {
    reset();
    resetMap();

    fromChildrenInput.current?.reset_value("fromSelectize");
    toChildrenInput.current?.reset_value("toSelectize");
    disabilityTypeChildrenInput.current?.reset_value(
      "disabilityTypeCustomSelect"
    );
    assistiveResourceChildrenInput.current?.reset_value(
      "assistiveResourceCustomSelect"
    );
    transpotChildrenInput.current?.reset_value("transportListCustomSelect");
  };

  const isFirstRender = useRef(true);
  //Centraliza o mapa na localizacao oo usuario (caso concorde em compartilhar sua localizacao) e define como primeira origem
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        addRouteMakers([coords.lat, coords.lng], true);
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
          )
          .then(({ data }) => {
            setValue("from", data.results[0].formatted_address, {
              shouldDirty: true,
            });
            setValue("from_cords", `${coords.lng},${coords.lat}`, {
              shouldDirty: true,
            });

            if (isFirstRender.current) {
              if (
                !data.plus_code.compound_code.includes("Salvador") &&
                !data.plus_code.compound_code.includes("Foz do Iguaçu")
              ) {
                toast.info(
                  "Esta cidade ainda não possui rotas acessíveis customizadas."
                );
              }
              isFirstRender.current = false;
            }
          })
          .catch((error) => {
            console.log(error);
            return undefined;
          });
      }
    );
  }, []);

  return (
    <>
      <Global />
      <BarStyle {...params}>
        <div className="fake_background" />
        <div className="logo">
          <img src="/images/izzistrit_logo.png" alt="" />
        </div>
        <div
          className="headerContent"
          style={{
            display: handleScreenSize()
              ? "flex"
              : !handleScreenSize() && !showFields
              ? "none"
              : "inline",
          }}
        >
          <LocationForm onSubmit={handleSubmit(onSubmit)}>
            {/* From */}
            <SelectizeComponent
              dataTarget="reactour__from"
              ref={fromChildrenInput}
              className="InputComponent"
              id="fromSelectize"
              error={errors.to}
              label={i18n.t("bar.router.from") || ""}
              previusData={userCoords}
              parentCallback={(coord: AddressProps) => {
                go_to_location(coord.lat, coord.lon, mapState);
                addRouteMakers([coord.lat, coord.lon], true);
                setValue("from", coord.display_name, { shouldDirty: true });
                setValue("from_cords", `${coord.lon},${coord.lat}`, {
                  shouldDirty: true,
                });
              }}
            />
            {/* To */}
            <SelectizeComponent
              dataTarget="reactour__to"
              ref={toChildrenInput}
              className="InputComponent"
              id="toSelectize"
              error={errors.to}
              label={i18n.t("bar.router.to") || ""}
              previusData={userCoords}
              parentCallback={(coord: AddressProps) => {
                go_to_location(coord.lat, coord.lon, mapState);
                addRouteMakers([coord.lat, coord.lon], false);
                setValue("to", coord.display_name, { shouldDirty: true });
                setValue("to_cords", `${coord.lon},${coord.lat}`, {
                  shouldDirty: true,
                });
              }}
            />

            {/* Tipo de deficiência */}
            <CustomSelectComponent
              register={{ ...register("disability_type_label") }}
              dataTarget="reactour__disabilityType"
              ref={disabilityTypeChildrenInput}
              className="InputComponent"
              id="disabilityTypeCustomSelect"
              label={i18n.t("bar.router.disabilityTypeList") || ""}
              options={disabilityTypeList}
              parentCallback={(value: string) => {
                handleDisabilityTypeLocal(value, true);
                handleLinkedAssistiveResourceLocal(value, true);
                handleLinkedAssistiveResourceFilter(value);
                setValue("disability_type", value, { shouldDirty: true });
              }}
            />

            {/* Recurso assistivo */}
            <CustomSelectComponent
              register={{ ...register("assistive_resource_label") }}
              dataTarget="reactour__assistiveResource"
              ref={assistiveResourceChildrenInput}
              className="InputComponent"
              id="assistiveResourceCustomSelect"
              label={i18n.t("bar.router.assistiveResource") || ""}
              customStyle={{
                disable:
                  keepingLinkedAssistive.length == 0 && !auth.isAuthenticated(),
              }}
              options={keepingLinkedAssistive}
              parentCallback={(value: string) => {
                setValue("assistive_resource", value, { shouldDirty: true });
              }}
            />

            {/* Tranporte */}
            <CustomSelectComponent
              register={{ ...register("transport_label", { required: true }) }}
              dataTarget="reactour__transportList"
              ref={transpotChildrenInput}
              className="InputComponent"
              id="transportListCustomSelect"
              label={i18n.t("bar.router.transport") || ""}
              error={errors.transport}
              options={transportList}
              parentCallback={(value: string) => {
                setValue("transport", value, { shouldDirty: true });
              }}
            />

            <div style={{ display: "flex", margin: "5px 0px" }}>
              <Button
                className="btn-go"
                data-tut="reactour__goButtom"
                onClick={() => {
                  handleShowSearchMenu();
                }}
              >
                {i18n.t("bar.router.goButtom")}
              </Button>

              {isDirty && (
                <Button
                  className="btn-clear"
                  type="reset"
                  onClick={resetRouter}
                >
                  {i18n.t("bar.router.clearButtom")}
                </Button>
              )}
            </div>
          </LocationForm>
        </div>
        <div
          className="perfil"
          onClick={() =>
            auth.isAuthenticated() ? handleShowProfile() : handleShowModal()
          }
        >
          <div className="namebox">
            {handleScreenSize() ? auth.isAuthenticated() : null}
          </div>
          <div className="account">
            <Icons.Account />
          </div>
        </div>

        <div
          style={{
            display: handleScreenSize() ? "none" : "flex",
            marginBottom: "4px",
          }}
          onClick={handleShowSearchMenu}
        >
          {!showFields ? (
            <Icons.Menu />
          ) : (
            <>
              <CloseSection onClick={() => handleShowSearchMenu()} />{" "}
              <Icons.Close />
            </>
          )}
        </div>
      </BarStyle>

      {
        /**Modal inicial que dá a opção de o usuário continuar sem login ou se cadastrar na plataforma */
        showModal && !auth.isAuthenticated() ? (
          <UserModal.Initial show={showModal} handleClose={handleShowModal} />
        ) : null
      }

      {
        /**Modal de perfil do usuario. Exibido quando o usuário está autenticado*/
        <UserModal.Profile show={showProfile} handleClose={handleShowProfile} />
      }

      {
        /**Modal de avaliacao da plataforma */
        <NpsCard show={showNps} handleClose={handleShowNps} />
      }
    </>
  );
}

import styled from "styled-components";

export const Button = styled.button`
  box-sizing: border-box;
  border: none;

  width: 100%;
  height: 30px;
  margin: 0px 5px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;

  color: #fff;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: pointer;

  :hover {
    background: rgb(0, 0, 0, 0.7);
  }

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;

    width: max-content;

    padding: 18px;
  }
`;

export const LoginButton = styled.button`
  margin: 4px auto;
  padding: 9px 45px;

  box-sizing: border-box;
  border: none;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  align-items: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;

  background: #0093dd;
  border-radius: 3px;
`;

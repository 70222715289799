import React from "react";
import { CardContainer } from "./styles";
import Button from "../Button";
import icons from "../../../../../assets/icons";

const InviteCard = (props: {
  title: string;
  subtitle: string;
  googleLogin: Function;
  handleClose: Function;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <CardContainer>
      <div className="leftContent">
        <h1>{props.title}</h1>
        <span>{props.subtitle}</span>
      </div>
      <div className="rightContent">
        <Button
          functionRef={props.googleLogin}
          title="Continuar com Google"
          image="/icons/google-icon.svg"
          color="rgba(255, 75, 38, 1)"
          backgroundColor="rgba(216, 222, 233, 1)"
        />
        <Button
          functionRef={() => props.setIsLogin(false)}
          title="Entrar/Cadastrar"
          color="#ffffff"
          backgroundColor="rgba(46, 52, 64, 1)"
        />
        <div className="leave" onClick={() => props.handleClose()}>
          <input type="radio" name="radio" id="radio" />
          <label htmlFor="radio">Não, obrigado</label>
        </div>
      </div>

      <div onClick={() => props.handleClose()}>
        <icons.Close />
      </div>
    </CardContainer>
  );
};

export default InviteCard;

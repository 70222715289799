import { TourScreenProps } from "../../../config/interfaces"
import TourBallon from "../tourBallon";
import "./styles.css";

const TourScreen = ({
    message,
    sourceImage,
    altImage
}:TourScreenProps) => {
    return(
        <div className="tourScreenContainer">
                <img 
                  src={sourceImage}
                  alt={altImage}
                />
                <TourBallon 
                    message={message}
                />
            </div>
    )
}

export default TourScreen
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider
    clientId={`709158329107-k71hugg885c6vu1h1ulgq8rrfc7q4h0m.apps.googleusercontent.com`}
  >
    <React.StrictMode>
      <App />
      <ToastContainer autoClose={false} limit={3} draggable pauseOnHover />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

import { ActionProviderProps, ChatbotStateProps } from "../config/interfaces";

class MessageParser {
  actionProvider: ActionProviderProps;
  state: ChatbotStateProps;
  constructor(actionProvider: ActionProviderProps, state: ChatbotStateProps) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message: string) {
    if (this.actionProvider.getInitialized() === true) {
      if (this.actionProvider.getOrigin() === "") {
        this.actionProvider.originRoadmap(message);
      } else if (this.actionProvider.getDestination() === "") {
        this.actionProvider.destinationRoadmap(message);
      }
    } else {
      if (!this.actionProvider.getInitialized()) {
        this.actionProvider.handleValidation("start");
      } else {
        this.actionProvider.handleDefault();
      }
    }
  }
}
export default MessageParser;

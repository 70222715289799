import React from "react";
import { InputBox, NoResultsMessage } from "./styles";
import InputRadio from "../../components/InputRadio";
import { ChatbotProps } from "../../../config/interfaces";
import { i18n } from "../../../translate/i18n";

const Addresses = (props: ChatbotProps) => {
    let addressOrigin = props.actionProvider.getFromAddresses();

    function handleSubmit(origin: string, coords: string) {
        console.log(coords);

        props.actionProvider.setOrigin(origin);
        props.actionProvider.setFromCoords(coords);
        props.actionProvider.addMarker(coords, true);
        props.actionProvider.setLastInteraction("fromAddresses");
        props.actionProvider.validationWidgetLauncher(i18n.t('chatbot.widgets.placesMessages.isThere'));
    }

    function handleOptionNotFound() {
        props.actionProvider.setOrigin("");
        props.actionProvider.handleOptionNotFound();
    }

    function withoutResults() {
        return (<NoResultsMessage>
            <label>{i18n.t('chatbot.widgets.tryAgain')}</label>
        </NoResultsMessage>);
    }

    function withResults() {
        return (
            <InputBox>
                <form>
                    <InputRadio
                        submit={handleOptionNotFound}
                        label={i18n.t('chatbot.widgets.placesMessages.didntFound') as string}
                        value={""}
                        index={-1}
                    />
                    {
                        addressOrigin.map((element, index) => {
                            return (
                                <InputRadio
                                    key={index}
                                    submit={handleSubmit}
                                    label={element.display_name}
                                    value={element.lon + "," + element.lat}
                                    index={index}
                                />
                            );
                        })
                    }
                </form>

            </InputBox>);
    }

    return addressOrigin.length === 0 ? (
        withoutResults()
    ) : (
        withResults()
    );
};

export default Addresses;
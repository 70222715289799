import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  position: absolute;
  top: 25vh;
  right: 20vw;
  bottom: auto;
  left: 20vw;
  padding: 40px;
  box-shadow: 10px 20px 40px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    fill: #5a5a5a;
    cursor: pointer;

    :hover {
      fill: rgba(255, 0, 0, 1);
    }
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    width: 50%;

    h1 {
      font-family: "Poppins", sans-serif;
    }

    span {
      margin: 10px 0px;
      font-family: "DM Sans", sans-serif;
    }
  }

  .rightContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10%;
    .leave {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-top: 10px;
      font-size: 14px;
      font-family: "DM Sans", sans-serif;

      input {
        margin-right: 5px;
        cursor: pointer;
      }

      label {
        cursor: pointer;
        color: #2a2727;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 15vh;
    left: 5vw;
    right: 5vw;
    bottom: auto;

    .leftContent {
      width: 100%;
      text-align: center;
    }
    .rightContent {
      width: 100%;
      padding-left: 0;
    }
  }
`;

export { CardContainer };

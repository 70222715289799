import { AssistiveResourceProps } from "./interfaces";
import { i18n } from "../translate/i18n";

//Modos de deslocamento
export const transportList = [
  { label: i18n.t("consts.transportList.walking"), value: "pe" },
  { label: i18n.t("consts.transportList.driving"), value: "carro" },
  { label: i18n.t("consts.transportList.bike"), value: "bicicleta" },
  { label: i18n.t("consts.transportList.public"), value: "publico" },
  { label: i18n.t("consts.transportList.taxi"), value: "taxi" },
  { label: i18n.t("consts.transportList.app"), value: "aplicativo" },
];

//Tipos de deficiência
export const disabilityTypeList = [
  {
    label: i18n.t("consts.disabilityTypeList.cognitive"),
    value: "Cognitiva",
    link: [2, 3, 4],
  },
  {
    label: i18n.t("consts.disabilityTypeList.hearing"),
    value: "Auditiva",
    link: [1],
  },
  {
    label: i18n.t("consts.disabilityTypeList.physical"),
    value: "Fisica",
    link: [2, 3, 4, 6, 7, 8],
  },
  {
    label: i18n.t("consts.disabilityTypeList.visual"),
    value: "Visual",
    link: [3, 5],
  },
  {
    label: i18n.t("consts.disabilityTypeList.other"),
    value: "Nenhum",
    link: [0],
  },
];

//Recursos assistivos
export const assistiveResourceList = [
  {
    id: 6,
    label: i18n.t("consts.assistiveResourceList.crutch"),
    value: "Muleta",
  },
  {
    id: 5,
    label: i18n.t("consts.assistiveResourceList.guideDog"),
    value: "Caoguia",
  },
  {
    id: 1,
    label: i18n.t("consts.assistiveResourceList.hearingAid"),
    value: "AparelhoAuditivo",
  },
  { id: 8, label: i18n.t("consts.assistiveResourceList.llp"), value: "PMI" },
  { id: 7, label: i18n.t("consts.assistiveResourceList.ulp"), value: "PMS" },
  {
    id: 2,
    label: i18n.t("consts.assistiveResourceList.walker"),
    value: "Andador",
  },
  {
    id: 3,
    label: i18n.t("consts.assistiveResourceList.walkingStick"),
    value: "Bengala",
  },
  {
    id: 4,
    label: i18n.t("consts.assistiveResourceList.wheelchair"),
    value: "CadeiradeRodas",
  },
];

/**
 * Recebe o valor do recurso assistivo e tornar sua respectiva label
 * @param value
 * @returns label
 */
export const getAssistiveResourceLabel = (value: string | undefined) => {
  let label = "Não utilizo";
  assistiveResourceList.map((element) => {
    if (element.value == value) {
      label = element.label;
    }
  });
  return label;
};

/**
 * Recebe o valor do tipo de deficiencia e tornar sua respectiva label
 * @param value
 * @returns label
 */
export const getDisabilityTypeLabel = (value: string | undefined) => {
  let label = "Não possuo";
  disabilityTypeList.map((element) => {
    if (element.value == value) {
      label = element.label;
    }
  });
  return label;
};

/**
 * @param param Tipo de deficiência
 * @returns Recursos assistivos vinculados ao tipo de deficiência
 */
export const getAssistiveResource = (param: string) => {
  let results: Array<AssistiveResourceProps> = [];
  let aux: Array<Number> = [];

  disabilityTypeList.forEach((e) => {
    if (e.value === param) {
      aux = e.link;
    }
  });
  aux.forEach((e) => {
    assistiveResourceList.forEach((i) => {
      if (e === i.id) {
        results.push(i);
      }
    });
  });
  return results;
};

/**
 * @param type Tipo de deficiência marcado ou desmarcado
 * @param checked Estado do campo (marcado ou desmarcado)
 * @param setState Ação do estado de tipos de deficiência
 * @param state Estado dos tipos de deficiência
 */
export function handleDisabilityType(
  type: string,
  checked: boolean,
  setState: React.Dispatch<React.SetStateAction<string[]>>,
  state: Array<string>
) {
  if (checked) {
    setState([...state, type]);
  } else {
    setState(state.filter(checkType));
  }

  function checkType(param: string) {
    return param !== type;
  }
}

/**
 * @param type Tipos de deficiência marcado ou desmarcado
 * @param checked Estado do campo (marcado ou desmarcado)
 * @param state Estado dos tipos de deficiência
 * @param setState Ação do estado dos tipos de deficiência
 * @param noFilteredState Estado auxiliar. Tipos de deficiência sem filtragem
 * @param setNoFilteredState Ação do estado dos tipos de deficiência sem filtragem
 */
export function handleLinkedAssistiveResource(
  type: string,
  checked: boolean,
  state: Array<AssistiveResourceProps>,
  setState: React.Dispatch<React.SetStateAction<AssistiveResourceProps[]>>,
  noFilteredState: Array<AssistiveResourceProps>,
  setNoFilteredState: React.Dispatch<
    React.SetStateAction<AssistiveResourceProps[]>
  >
) {
  let results = getAssistiveResource(type);
  if (checked) {
    setNoFilteredState(noFilteredState.concat(results));
    setState(filterArray(state.concat(results)));
  } else {
    let array = removeAssistiveResource(results, noFilteredState);
    setState(filterArray(array));
    setNoFilteredState(array);
  }
}

/**Alterar display de acordo com o tamanho da tela */
export function handleScreenSize() {
  return window.screen.width > 768;
}

/**
 * @param param Lista de elementos do tipo AssistiveResourceProps
 * @returns Lista sem elementos repetidos
 */
function filterArray(param: Array<AssistiveResourceProps>) {
  let filtered = param.filter((este, i) => {
    return param.indexOf(este) === i;
  });

  filtered.sort((e1, e2) => {
    return e1.id > e2.id ? 1 : e2.id > e1.id ? -1 : 0;
  });

  return filtered;
}

/**
 * @param remove Lista de elementos que serão removidos
 * @param rest Lista de elementos selecionados até o momento (no estado não filtrado)
 * @returns Lista de elementos vinculados aos tipos de deficiência ainda selecionados
 */
function removeAssistiveResource(
  remove: Array<AssistiveResourceProps>,
  rest: Array<AssistiveResourceProps>
) {
  remove.forEach((e) => {
    let selectedIndex = -1;
    rest.forEach((i, index) => {
      if (e.id === i.id) {
        selectedIndex = index;
      }
    });
    if (selectedIndex !== -1) {
      rest.splice(selectedIndex, 1);
    }
  });

  return rest;
}

import React from "react";
import { ChatbotProps } from "../../../config/interfaces";
import { i18n } from "../../../translate/i18n";
import { Input, InputBox } from "./styles";

const Validation = (props: ChatbotProps) => {
    return <>
        <InputBox>
            <Input
                onClick={() => {
                    props.actionProvider.handleValidation(props.actionProvider.getLastInteraction(), true);
                }}
                type="button"
                value={i18n.t('chatbot.widgets.confirm.yes') as string}
            />
            <Input
                onClick={() => {
                    props.actionProvider.handleValidation(props.actionProvider.getLastInteraction(), false);
                }}
                type="button"
                value={i18n.t('chatbot.widgets.confirm.no') as string}
            />
        </InputBox>
    </>;
};

export default Validation;
import styled from "styled-components";
import { Button } from "../buttons/buttonStyles";

export const Legenda = styled.div`
  position: fixed;
  bottom: 17px;
  right: 10px;

  user-select: none;
  background-color: white;
  margin: 0px -10px;
  min-width: 100px;
  padding: 10px 10px 10px 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 15px;

  box-sizing: border-box;
  cursor: default;
  img {
    width: 30px;
  }

  .item {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  @media screen and (max-width: 768px) {
    margin: 0px -8px;
    font-size: 12px;

    img {
      width: 20px;
    }
  }
`;

export const Nps = styled.div`
  display: flex;
  max-width: 31vw;
  min-width: 400px;
  height: fit-content;

  position: absolute;
  bottom: 17px;
  left: 0;

  padding: 5px 25px 10px 5px;
  align-items: center;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  font-size: 14px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);

  ${Button} {
    max-width: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  svg {
    width: 20px;
    position: absolute;
    top: 2px;
    right: 2px;

    fill: #fff;
    cursor: pointer;

    :hover {
      fill: red;
    }
  }

  .rating {
    ul {
      display: flex;
      list-style-type: none;
      margin-top: 10px;
      margin-left: 5px;
      justify-content: center;

      li {
        input {
          display: none;
        }

        label {
          padding: 5px 15px 3px 15px;
          cursor: pointer;
          align-items: center;

          :hover {
            opacity: 0.4;
          }
        }
      }
    }
  }

  .inputWidget {
    height: 50px;
    width: 100%;
    margin: 10px 5px 15px 3px;
    padding: 0px 10px;
    color: rgba(0, 0, 0, 1);
    background-color: rgba(250,250,250,255);

    border-radius: 8px;
    border: none;
    outline: none;

    font-size: 14px;
    font-weight: 500;
  }

  .formatButton {
    display: flex;
    justify-content: end;
  }

  .formatInput {
    width: 95%;
  }

  @media screen and (max-width: 768px) {
    max-width: 93vw;
    min-width: 93vw;

    position: fixed;
    bottom: 16vh;

    border-radius: 0;

    .rating {
      ul {
        margin-left: 0;
        li {
          label {
            padding: 5px 10px 3px 10px;
          }
        }
      }
    }
  }
`;

export const ChatBotIcon = styled.div`
  position: fixed;
  bottom: 160px;
  right: 10px;

  cursor: pointer;

  img {
    width: 50px;
    border-radius: 50%;
    border: solid 2px rgba(0, 0, 0, 0.8);

    transition: width 0.5s;
  }

  #description {
    display: none;
  }

  :hover {
    img {
      width: 60px;
      border-radius: 50%;
    }

    #description {
      display: flex;
      position: absolute;
      bottom: 15px;
      right: 70px;
      width: 150px;

      padding: 5px;
      text-align: center;

      border-radius: 10px;

      background-color: rgba(0, 0, 0, 0.4);
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: 130px;
    margin: 0px -8px;
    font-size: 12px;

    img {
      width: 40px;
    }

    :hover {
      #description {
        display: none;
      }
    }
  }
`;

import { useState } from "react";
import { Legenda, Nps } from "./widgetStyles";
import icons from "../../assets/icons";
import appService from "../../../controllers/app/appService";
import { Button } from "../buttons/buttonStyles";
import styled from "styled-components";

import { i18n } from "../../../translate/i18n";

export const PointsLegend = () => {
  return (
    <Legenda>
      <div style={{ paddingLeft: 5, paddingBottom: 3, fontWeight: "bold" }}>
        {i18n.t("legend.classification")}
      </div>
      <div
        style={{
          borderWidth: 1,
          borderColor: "#6c757d",
          marginTop: 2,
          marginBottom: 4,
          marginLeft: -5,
          width: "calc(100% + 15px)",
          borderStyle: "solid ",
        }}
      />
      <div className="item">
        <img src="icons/circle-green.png" alt="" />
        {i18n.t("legend.favourable")}
      </div>
      <div className="item">
        <img src="icons/circle-yellow.png" alt="" />
        {i18n.t("legend.acceptable")}
      </div>
      <div className="item">
        <img src="icons/circle-red.png" alt="" />
        {i18n.t("legend.critical")}
      </div>
    </Legenda>
  );
};

interface Props {
  showNps: boolean;
  setShow: (param: boolean) => void;
}

export const NpsComponent = ({ setShow, showNps }: Props) => {
  const [showText, setShowText] = useState<boolean>(false);
  const [avaliacao, setAvaliacao] = useState<string>("");
  const [target, setTarget] = useState<number>(0);

  function handleDisplay(param: boolean) {
    setShow(false);
    setShowText(false);
    appService.incrementShowNps(param);
  }

  function submitRating() {
    appService.saveNpsReview(target, avaliacao);
    handleDisplay(true);
  }

  function handleClick(e: any) {
    setShowText(true);
    setTarget(parseInt(e.target.value));
  }

  return (
    <Nps style={{ display: showNps ? "block" : "none" }}>
      {showText ? (
        <div className="textComponent">
          <label>{i18n.t("legend.nps.title")}</label>
          <div className="formatInput">
            <textarea
              placeholder={i18n.t("legend.nps.sub") || ""}
              className="inputWidget"
              onChange={(e) => setAvaliacao(e.target.value)}
            />
          </div>

          <div className="formatButton">
            <Button onClick={submitRating}>{i18n.t("legend.nps.send")}</Button>
          </div>
        </div>
      ) : (
        <div className="rating">
          <span>{i18n.t("legend.nps.question")}</span>
          <br />
          <ul>
            <li>
              <label
                htmlFor="0"
                style={{ backgroundColor: "rgba(238, 53, 52, 1)" }}
              >
                0
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={0}
                id="0"
              />
            </li>
            <li>
              <label
                htmlFor="1"
                style={{ backgroundColor: "rgba(246, 70, 70, 1)" }}
              >
                1
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={1}
                id="1"
              />
            </li>
            <li>
              <label
                htmlFor="2"
                style={{ backgroundColor: "rgba(254, 101, 79, 1)" }}
              >
                2
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={2}
                id="2"
              />
            </li>
            <li>
              <label
                htmlFor="3"
                style={{ backgroundColor: "rgba(255, 137, 67, 1)" }}
              >
                3
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={3}
                id="3"
              />
            </li>
            <li>
              <label
                htmlFor="4"
                style={{ backgroundColor: "rgba(253, 168, 59, 1)" }}
              >
                4
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={4}
                id="4"
              />
            </li>
            <li>
              <label
                htmlFor="5"
                style={{ backgroundColor: "rgba(255, 193, 32, 1)" }}
              >
                5
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={5}
                id="5"
              />
            </li>
            <li>
              <label
                htmlFor="6"
                style={{ backgroundColor: "rgba(235, 189, 15, 1)" }}
              >
                6
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={6}
                id="6"
              />
            </li>
            <li>
              <label
                htmlFor="7"
                style={{ backgroundColor: "rgba(192, 203, 15, 1)" }}
              >
                7
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={7}
                id="7"
              />
            </li>
            <li>
              <label
                htmlFor="8"
                style={{ backgroundColor: "rgba(157, 205, 6, 1)" }}
              >
                8
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={8}
                id="8"
              />
            </li>
            <li>
              <label
                htmlFor="9"
                style={{ backgroundColor: "rgba(121, 204, 1, 1)" }}
              >
                9
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={9}
                id="9"
              />
            </li>
            <li>
              <label
                htmlFor="10"
                style={{ backgroundColor: "rgba(47, 177, 2, 1)" }}
              >
                10
              </label>
              <input
                onClick={(e) => {
                  handleClick(e);
                }}
                name="rating"
                defaultValue={10}
                id="10"
              />
            </li>
          </ul>
        </div>
      )}
      <div onClick={() => handleDisplay(false)}>
        <icons.Close />
      </div>
    </Nps>
  );
};

export const ChatBotIcon = styled.div`
  position: fixed;
  bottom: 160px;
  right: 10px;

  cursor: pointer;

  img {
    width: 50px;
    border-radius: 50%;
    border: solid 2px rgba(0, 0, 0, 0.6);

    transition: width 0.5s;
  }

  #description {
    display: none;
  }

  :hover {
    img {
      width: 60px;
      border-radius: 50%;
    }

    #description {
      display: flex;
      position: absolute;
      bottom: 15px;
      right: 70px;
      width: 150px;

      padding: 5px;
      text-align: center;

      border-radius: 10px;

      background-color: rgba(0, 0, 0, 0.4);
      color: white;
    }
  }

  @media screen and (max-width: 768px) {
    top: 50px;
    right: auto;
    bottom: auto;
    left: 15px;
    margin: 0px -8px;
    font-size: 12px;

    img {
      width: 40px;
    }

    :hover {
      #description {
        display: none;
      }
    }
  }
`;

import React from "react";
import { ChatbotProps } from "../../../config/interfaces";
import { Box, InfoCard, InputBox } from "./styles";
import { assistiveResourceList, disabilityTypeList, transportList } from "../../../config/constants";
import { i18n } from "../../../translate/i18n";

const ConfirmRoute = (props: ChatbotProps) => {
    let origin = props.actionProvider.getState().routeState.origin;
    let destination = props.actionProvider.getState().routeState.destination;

    let disabilityType = disabilityTypeList.map((type) => {
        return type.value === props.actionProvider.getState().routeState.disabilityType ? type.label : "";
    });
    let assistiveResource = assistiveResourceList.map((resource) => {
        return resource.value === props.actionProvider.getState().routeState.assistiveResource ? resource.label : "";
    });
    let transport = transportList.map((transport) => {
        return transport.value === props.actionProvider.getState().routeState.transport ? transport.label : "";
    });

    return <>
        <InfoCard>
            <li><strong>{i18n.t('chatbot.widgets.confirmRoute.origin')}</strong> {origin}</li>
            <li><strong>{i18n.t('chatbot.widgets.confirmRoute.destination')}</strong> {destination}</li>
            <li><strong>{i18n.t('chatbot.widgets.confirmRoute.disabilityType')}</strong> {disabilityType}</li>
            <li><strong>{i18n.t('chatbot.widgets.confirmRoute.assistiveResource')}</strong> {assistiveResource}</li>
            <li><strong>{i18n.t('chatbot.widgets.confirmRoute.transport')}</strong> {transport}</li>
        </InfoCard>
        <Box>

            <InputBox
                type="button"
                value={i18n.t('chatbot.widgets.confirmRoute.confirm') as string}
                />

            <InputBox
                type="button"
                value={i18n.t('chatbot.widgets.confirmRoute.return') as string}
            />
        </Box>

    </>;
};

export default ConfirmRoute;
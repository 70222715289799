import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { MapFunctions } from "./MapFunctions";
import {
  LatLngExpression,
  Map as MapType,
  GeoJSON as GeoJSONType,
} from "leaflet";
import { FeatureCollection, LineString, Point } from "geojson";
import { MapStateProps } from "../config/interfaces";

export default class MapStateControl {
  mapState: MapStateProps;
  mapRef: MutableRefObject<MapType | null>;
  routerLayer: MutableRefObject<GeoJSONType<any> | null>;
  setmapState: Dispatch<SetStateAction<MapStateProps>>;

  constructor(
    mapState?: MapStateProps,
    setmapState?: Dispatch<SetStateAction<MapStateProps>>,
    routerLayer?: MutableRefObject<GeoJSONType | null>,
    mapRef?: MutableRefObject<MapType | null>
  );
  constructor(
    mapState: MapStateProps,
    setmapState: Dispatch<SetStateAction<MapStateProps>>,
    routerLayer: MutableRefObject<GeoJSONType | null>,
    mapRef: MutableRefObject<MapType | null>
  ) {
    this.mapState = mapState;
    this.setmapState = setmapState;
    this.routerLayer = routerLayer;
    this.mapRef = mapRef;
  }

  MapManagement = new MapFunctions();

  addRouteMakers = (latlng: LatLngExpression, start: boolean = true) =>
    this.MapManagement.addRouteMakers(
      latlng,
      start,
      this.mapRef,
      this.mapState,
      this.setmapState
    );

  resetMap = () =>
    this.MapManagement.resetMap(
      this.mapState,
      this.setmapState,
      this.mapRef,
      this.routerLayer
    );

  setRouter = (geojson: FeatureCollection<LineString | Point>) =>
    this.MapManagement.setRouter(geojson, this.routerLayer, this.mapRef);
}

import styled from "styled-components";

export const Input = styled.input`
  height: 50px;
  width: 100%;
  background: rgba(0, 147, 221, 0.8);
  color: white;

  margin: 5px 0px;
  padding: 5px;
  border-radius: 10px;
  border: solid 1px #000;

  cursor: pointer;

  font-weight: "bold";

  :hover {
    background: rgba(0, 147, 221, 0.6);
  }
`;

export const InputBox = styled.div`
  display: block;
`;

import { BotAvatar } from "./assets/botAssets";
import { ChatbotProps } from "../config/interfaces";
import { createChatBotMessage } from "react-chatbot-kit";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";

//Widgets imports
import Start from "./widgets/Start";
import Options from "./widgets/Options";
import Transports from "./widgets/Transports";
import Validation from "./widgets/Validation";
import ToAddresses from "./widgets/ToAddresses";
import ConfirmRoute from "./widgets/ConfirmRoute";
import FromAddresses from "./widgets/FromAddresses";
import DisabilityType from "./widgets/DisabilityType";
import AssistiveResource from "./widgets/AssistiveResource";
import { i18n } from "../translate/i18n";


export const botName = "Izzi";

//Configurações da janela do chatbot
const config: IConfig = {
  initialMessages: [],
  botName: botName,
  //Estilização da Janela de chat
  customStyles: {
    botMessageBox: {
      backgroundColor: "rgba(0, 147, 221, .8)",
    },
    chatButton: {
      backgroundColor: "rgba(0, 100, 200, 1)",
    },
  },
  customComponents: {
    header: () => (
      <div
        style={{
          height: "30px",
          textAlign: "center",
          paddingTop: "10px",
          borderTopRightRadius: "6px",
          borderTopLeftRadius: "6px",

          fontWeight: "bold",

          background: "rgba(0, 147, 221, 1)",
          color: "white",
        }}
      >
        Chat {i18n.t('chatbot.conective')} {botName} [{<strong>BETA</strong>}]
      </div>
    ),
    //Configuraçao dos avatares
    botAvatar: (props: any) => <BotAvatar {...props} />,
    //userAvatar: (props) => <UserAvatar {...props} />,
  },
  //States
  state: {
    routeState: {
      initialized: false,
      fromAddresses: [],
      toAddresses: [],
      origin: "",
      destination: "",
      fromCoords: "",
      toCoords: "",
      disabilityType: "",
      assistiveResource: "",
      transport: "",
      lastInteraction: "start"
    },
    messages: [
      createChatBotMessage(
        i18n.t('chatbot.configMessages.createChat'),
        {}
      ),
      createChatBotMessage(i18n.t('chatbot.configMessages.startCreateChat'), {
        widget: "start",
      }),
    ]
  },

  //Widgets
  widgets: [
    {
      widgetName: "start",
      widgetFunc: (props: ChatbotProps) => <Start {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
    //Widget de lista de tipos de deficiência
    {
      widgetName: "disabilityType",
      widgetFunc: (props: ChatbotProps) => <DisabilityType {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },

    //Widget de lista de recursos assistivos
    {
      widgetName: "assistiveResource",
      widgetFunc: (props: ChatbotProps) => <AssistiveResource {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },

    //Widget de lista de transportes
    {
      widgetName: "transports",
      widgetFunc: (props: ChatbotProps) => <Transports {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },

    //Widget de resultados de pesquisa de local de partida
    {
      widgetName: "toAddresses",
      widgetFunc: (props: ChatbotProps) => <ToAddresses {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
    //Widget de resultados de pesquisa de local de destino
    {
      widgetName: "fromAddresses",
      widgetFunc: (props: ChatbotProps) => <FromAddresses {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
    {
      widgetName: "confirmRoute",
      widgetFunc: (props: ChatbotProps) => <ConfirmRoute {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
    {
      widgetName: "validation",
      widgetFunc: (props: ChatbotProps) => <Validation {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
    {
      widgetName: "options",
      widgetFunc: (props: ChatbotProps) => <Options {...props} />,
      mapStateToProps: ['routeState', 'messages'],
      props: {},
    },
  ],
};

export default config;

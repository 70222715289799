import {
  Map as MapType,
  GeoJSON as GeoJSONType,
  LatLngExpression,
} from "leaflet";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
// eslint-disable-next-line
import { FeatureCollection, LineString, Point } from "geojson";
import { MapStateProps } from "../config/interfaces";
import icons from "../AppComponents/assets/map";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export class MapFunctions {
  public setRouter(
    geojson: FeatureCollection<LineString | Point>,
    routerLayer: MutableRefObject<GeoJSONType | null>,
    mapRef: MutableRefObject<MapType | null>
  ) {
    if (routerLayer.current) {
      routerLayer.current.clearLayers(); // remove old data
      routerLayer.current.addData(geojson);

      if ((geojson.features[0]?.geometry as LineString)?.type)
        mapRef.current?.flyToBounds(
          (geojson.features[0].geometry as LineString).coordinates.map((a) => [
            a[1],
            a[0],
          ])
        );
    }
  }

  public resetMap(
    mapState: MapStateProps,
    setmapState: Dispatch<SetStateAction<MapStateProps>>,
    mapRef: MutableRefObject<MapType | null>,
    routerLayer: MutableRefObject<GeoJSONType<LineString | Point> | null>
  ) {
    if (mapState.start_route) {
      mapRef.current?.removeLayer(mapState.start_route);
    }
    if (mapState.end_route) {
      mapRef.current?.removeLayer(mapState.end_route);
    }

    routerLayer.current?.clearLayers();

    setmapState({
      ...mapState,
      start_route: undefined,
      end_route: undefined,
    });
  }

  public addRouteMakers(
    latlng: LatLngExpression,
    start: boolean = true,
    mapRef: MutableRefObject<MapType | null>,
    mapState: MapStateProps,
    setmapState: Dispatch<SetStateAction<MapStateProps>>
  ) {
    var marker = start
      ? mapState.start_route || null
      : mapState.end_route || null;

    if (marker) {
      marker.setLatLng(latlng);
      mapRef.current?.flyTo(latlng, 17);
    } else {
      var blueIcon = icons.BlueMarker();

      marker = L.marker(latlng, { icon: blueIcon });
      mapRef.current && marker.addTo(mapRef.current);

      var state = start ? { start_route: marker } : { end_route: marker };

      mapRef.current?.flyTo(latlng, 17);
      setmapState({
        ...mapState,
        ...state,
      });
    }
  }
}

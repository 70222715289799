import { InputBox } from "./styles";
import { ChatbotProps } from "../../../config/interfaces";
import { i18n } from "../../../translate/i18n";

const Start = (props: ChatbotProps) => {

    function handleSubmit() {
        props.actionProvider.originRequest()
        props.actionProvider.setInitialized(true)
    }

    return <>
        <InputBox
            onClick={() => {
                handleSubmit();
            }}
            type="button"
            value={i18n.t('chatbot.widgets.start') as string}
        />

    </>;
};

export default Start;
import styled from "styled-components";

export const Modal = styled.div`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1003;
  }
  .modal-main {
    position: relative;
    background: #fafafa;
    text-align: center;
    max-width: ${(props: { long?: boolean; min?: boolean }) =>
      props.long ? "1350px" : props.min ? "350px" : "1080px"};
    width: 100%;
    padding: ${(props) => (props.long ? "19px 7px" : "20px 15px")};
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    box-sizing: border-box;
    height: min-content;
    max-height: ${(props) => "calc(100% - 100px)"};
    overflow: auto;
    top: 50%;
    left: 50%;
    z-index: 1003;
    transform: translate(-50%, -50%);
  }
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    height: min-content;
    user-select: none;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .children {
    overflow-y: auto;
    padding: ${(props) => (props.long ? "10px 10px" : "0px 55px")};
    max-height: ${(props) => "calc(100vh - 200px)"};
    box-sizing: border-box;
    height: auto;
  }

  .header {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
  }

  .close-section {
    position: absolute;
    top: 0;
    right: 0;

    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.6);
    z-index: 1002;
  }
  @media screen and (max-width: 768px) {
    .modal-main {
      max-height: ${(props) => "calc(100% - 50px)"};
      padding: 5px;
    }

    .children {
      padding-left: 10px;
      padding-right: 5px;
    }
  }
`;

export const CloseSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

import styled from "styled-components";

export const InputBox = styled.div`
  height: fit-content;
  width: fit-content;
  background: rgba(0, 147, 221, 0.8);
  color: white;

  padding: 10px;
  border-radius: 10px;

  form {
    display: grid;
    align-items: center;
  }
`;

import axios from "axios";
import { SetStateAction, useEffect, useState } from "react";
import icons from "../../../assets/icons";
import { CloseSection } from "../main/modalStyles";
import { Button } from "../../buttons/buttonStyles";
import { useGoogleLogin } from "@react-oauth/google";
import auth from "../../../../controllers/user/auth";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  AssistiveResourceProps,
  InputLoginProps,
  InputRegistrationProps,
  ModalProps,
} from "../../../../config/interfaces";
import {
  disabilityTypeList,
  getAssistiveResource,
  getAssistiveResourceLabel,
  getDisabilityTypeLabel,
} from "../../../../config/constants";
import { ContainerModal, ModalEditProfile, ModalProfile } from "./modalStyles";
import LoginFormCard from "./components/LoginFormCard";
import RegisterFormCard from "./components/RegisterFormCard";
import InviteCard from "./components/InviteCard";
import { toast } from "react-toastify";

function Initial({ handleClose, show }: ModalProps) {
  //Gerenciadores dos estados das transicoes dos componentes
  const [isLogin, setIsLogin] = useState(true);
  const [isRegister, setIsRegister] = useState(true);
  //Gerenciadores de estado dos formularios
  const loginForm = useForm<InputLoginProps>();
  const registerForm = useForm<InputRegistrationProps>({
    defaultValues: {
      user_id: null,
      name: "",
      email: "",
      age: 0,
      password: "",
      confirm_password: "",
      profile_image: null,
      assistive_resource: null,
      disability_type: null,
      allow_updates: false,
    },
  });
  const onSubmitLogin: SubmitHandler<InputLoginProps> = async (data) => {
    try {
      await toast.promise(auth.login(data), {
        pending: "Realizando login...",
        success: {
          render() {
            return "Login realizado com sucesso!";
          },
          autoClose: 1000,
        },
      });

      handleClose();

      //Registra que o usuario ja conhece a plataforma
      if (!auth.getDataStorage()) {
        auth.setDataStore(true);
      }

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status == 400) {
        toast.error("Informações de login incorretas.", {
          autoClose: 2500,
        });
      } else {
        toast.error(
          "Ocorreu um erro do sistema ao tentar realizar a operação!",
          {
            autoClose: 2500,
          }
        );
      }
    }
  };

  const onSubmitRegistration: SubmitHandler<InputRegistrationProps> = async (
    data
  ) => {
    try {
      await toast.promise(auth.register(data), {
        pending: "Realizando cadastro...",
        success: {
          render() {
            return "Cadastro realizado com sucesso!";
          },
          autoClose: 1000,
        },
      });
      await toast.promise(
        auth.login({ email: data.email, password: data.password }),
        {
          pending: "Realizando login...",
          success: {
            render() {
              return "Login realizado com sucesso!";
            },
            autoClose: 1000,
          },
        }
      );
      handleClose();
    } catch (error) {
      console.log(error);

      if (axios.isAxiosError(error) && error.response?.status == 409) {
        toast.error("Este email já está cadastrado no sistema.", {
          autoClose: 2500,
        });
      } else {
        toast.error(
          "Ocorreu um erro do sistema ao tentar realizar a operação!",
          {
            autoClose: 2500,
          }
        );
      }
    }
  };

  //Funcoes de login social
  async function getUserInfo(accessToken: string) {
    try {
      const response = await axios.get(
        "https://openidconnect.googleapis.com/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Erro ao obter informações do usuário:", error);
      throw error;
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await getUserInfo(tokenResponse.access_token);
      try {
        //Faz a solicitação de login e fechar o modal se for bem sucedida
        await toast.promise(
          auth.login({
            user_id: userInfo.sub,
          }),
          {
            pending: "Realizando Login...",
            success: {
              render() {
                return "Login realizado com sucesso!";
              },
              autoClose: 1000,
            },
          }
        );

        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (error) {
        //Se acontecer algum erro no login (erro interno ou usuario não encontrado) trataremos aqui
        //error.response.status: 400 -> Email não encontrado
        //error.response.status: 0 -> Erro na conexão
        if (axios.isAxiosError(error) && error.response?.status == 400) {
          try {
            //Cadastra o usuario
            await toast.promise(
              auth.register({
                user_id: userInfo.sub,
                name: userInfo.name,
                email: userInfo.email,
                profile_image: userInfo.picture,
                allow_updates: true,
              }),
              {
                pending: {
                  render() {
                    return "Realizando cadastro...";
                  },
                },
                success: {
                  render() {
                    return "Cadastro Realizado com sucesso!";
                  },
                  autoClose: 1200,
                },
              }
            );

            //realiza o login logo apos o cadastro
            await toast.promise(auth.login({ user_id: userInfo.sub }), {
              pending: {
                render() {
                  return "Realizando login...";
                },
              },
              success: {
                render() {
                  return "Login realizado com sucesso!";
                },
                autoClose: 1500,
              },
            });
            handleClose();

            setTimeout(() => {
              window.location.reload();
            }, 500);
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status == 409) {
              //Quando o usuario se cadastrou com o formulario e tentou logar com o google, adicionamos seu userId nos seus dados e realizamos login
              const data = {
                email: userInfo.email,
                user_id: userInfo.sub,
                profile_image: userInfo.picture,
              };
              try {
                await toast.promise(auth.update(data), {
                  pending: "Atualizando informações...",
                  success: {
                    render() {
                      return "Informações atualizadas com sucesso!";
                    },
                    autoClose: 1000,
                  },
                  error: {
                    render() {
                      return "Falha ao atualizar informações.";
                    },
                    autoClose: 2000,
                  },
                });
                await toast.promise(
                  auth.login({
                    user_id: userInfo.sub,
                  }),
                  {
                    success: {
                      render() {
                        return "Login realizado com sucesso!";
                      },
                      autoClose: 1000,
                    },
                  }
                );
                handleClose();

                setTimeout(() => {
                  window.location.reload();
                }, 500);
              } catch (error) {
                toast.error("Falha ao realizar login.", { autoClose: 2000 });
                console.log(error);
              }
            }
          }
        } else {
          toast.error("Falha ao conectar nos servidores!", {
            autoClose: 2000,
          });
        }
      }

      //Registra que o usuario ja conhece a plataforma
      if (!auth.getDataStorage()) {
        auth.setDataStore(true);
      }
    },
    onError: (error) => error,
  });

  const alreadyKnows = () => {
    return auth.getDataStorage();
  };

  return (
    <>
      <CloseSection
        {...{ handleClose, show }}
        onClick={() => handleClose()}
        style={{
          display: show ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 1,
        }}
      />

      {isLogin ? (
        alreadyKnows() ? (
          <InviteCard
            title="Já faz parte da nossa rede?"
            subtitle="Cadastre-se e faça login para melhorar a experiência com a geração das rotas personalizadas"
            googleLogin={googleLogin}
            handleClose={handleClose}
            setIsLogin={setIsLogin}
          />
        ) : (
          <InviteCard
            title="Quer fazer parte da nossa rede?"
            subtitle="Cadastre-se para receber nossas atualizações e melhorar a experiência com a geração das rotas personalizadas"
            googleLogin={googleLogin}
            handleClose={handleClose}
            setIsLogin={setIsLogin}
          />
        )
      ) : (
        <ContainerModal>
          {isRegister ? (
            <LoginFormCard
              googleLogin={googleLogin}
              handleClose={handleClose}
              loginForm={loginForm}
              onSubmitLogin={onSubmitLogin}
              setIsLogin={setIsRegister}
            />
          ) : (
            <RegisterFormCard
              googleRegister={googleLogin}
              handleClose={handleClose}
              onSubmitRegistration={onSubmitRegistration}
              registerForm={registerForm}
              setIsLogin={setIsRegister}
            />
          )}

          <div onClick={() => handleClose()}>
            <icons.Close />
          </div>
        </ContainerModal>
      )}
    </>
  );
}

function Profile({ handleClose, show }: ModalProps) {
  let user = auth.getUser();
  const [edit, setEdit] = useState(false);
  const [assistiveResources, setAssistiveResources] = useState<
    Array<AssistiveResourceProps>
  >([
    {
      id: 0,
      label: "",
      value: "",
    },
  ]);
  const [data, setData] = useState({
    name: user?.name,
    email: user?.email,
    disability_type: user?.disability_type,
    assistive_resource: user?.assistive_resource,
    profile_image: user?.profile_image,
  });

  const handleDataUpdate = async (params: { name: string; value: string }) => {
    setData((prev) => {
      return {
        ...prev,
        [`${params.name}`]: params.value,
      };
    });
  };

  const onSubmitUpdate = async () => {
    setData((prev) => {
      return {
        ...prev,
        email: user?.email,
      };
    });

    try {
      await toast.promise(auth.update(data), {
        pending: "Atualizando informações...",
        success: {
          render() {
            user = auth.getUser();
            setData({
              name: user?.name,
              email: user?.email,
              disability_type: user?.disability_type,
              assistive_resource: user?.assistive_resource,
              profile_image: user?.profile_image,
            });

            return "Informações atualizadas com sucesso!";
          },
          autoClose: 1000,
        },
        error: {
          render() {
            return "Falha ao atualizar informações.";
          },
          autoClose: 2000,
        },
      });
      handleClose();

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  const logout = () => {
    auth.logout();
    handleClose();

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    let userAssistiveResourceLinked: SetStateAction<AssistiveResourceProps[]> =
      [];
    if (user?.disability_type) {
      userAssistiveResourceLinked = getAssistiveResource(user?.disability_type);
    }
    setAssistiveResources(userAssistiveResourceLinked);
  }, []);

  return (
    <>
      {show ? (
        <>
          {edit ? (
            <ModalEditProfile>
              <img src="/icons/arrow-up.svg" className="arrow" alt="" />
              <p>Editar Informações do Perfil</p>
              <div className="basic-information">
                <img
                  src={
                    user?.profile_image
                      ? user.profile_image
                      : "/images/acc_image.png"
                  }
                  referrerPolicy="no-referrer"
                  className="img-profile"
                  alt="Imagem de perfil do usuário"
                />
                <div className="username">{user?.name}</div>
                <div className="no-content"></div>
              </div>
              <div className="info">
                <label>NOME</label>
                <input
                  placeholder={user?.name}
                  onChange={(e) =>
                    handleDataUpdate({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                  type="text"
                  name="name"
                />
                <br />

                <label>TIPO DE DEFICIÊNCIA</label>
                <select
                  name="disability_type"
                  defaultValue={user?.disability_type}
                  onChange={(e) => {
                    handleDataUpdate({
                      name: e.target.name,
                      value: e.target.value,
                    });
                    setAssistiveResources(getAssistiveResource(e.target.value));
                  }}
                  id=""
                >
                  <option>
                    {getDisabilityTypeLabel(user?.disability_type)}
                  </option>
                  {disabilityTypeList.map((element, index) => {
                    return (
                      <>
                        <option key={index} value={element.value}>
                          {element.label}
                        </option>
                      </>
                    );
                  })}
                </select>
                <br />

                <label>RECURSO ASSISTIVO</label>
                <select
                  onChange={(e) =>
                    handleDataUpdate({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                  name="assistive_resource"
                  id=""
                >
                  <option>
                    {getAssistiveResourceLabel(user?.assistive_resource)}
                  </option>
                  {assistiveResources.map((element, index) => {
                    return (
                      <>
                        <option key={index} value={element.value}>
                          {element.label}
                        </option>
                      </>
                    );
                  })}
                </select>
                <br />

                <label>ALTERAR IMAGEM DE PERFIL</label>
                <input
                  disabled
                  onChange={(e) =>
                    handleDataUpdate({
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  name="profile_image"
                  id=""
                />
              </div>
              <div className="buttons">
                <Button
                  type="button"
                  className="cancel"
                  onClick={() => {
                    handleClose();
                    setEdit(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  className="save"
                  onClick={() => {
                    onSubmitUpdate();
                    setEdit(false);
                  }}
                >
                  Salvar
                </Button>
              </div>
            </ModalEditProfile>
          ) : (
            <>
              <CloseSection
                {...{ handleClose, show }}
                onClick={() => handleClose()}
                style={{
                  display: show ? "block" : "none",
                }}
              />
              <ModalProfile>
                <img src="/icons/arrow-up.svg" className="arrow" alt="" />
                <div className="basic-information">
                  <img
                    src={
                      user?.profile_image
                        ? user.profile_image
                        : "/images/acc_image.png"
                    }
                    referrerPolicy="no-referrer"
                    className="img-profile"
                    alt="Imagem de perfil do usuário"
                  />
                  <div className="username">{user?.name}</div>
                  <div className="no-content"></div>
                </div>

                <div className="info">
                  <div className="item">
                    <label>EMAIL</label>
                    <br />
                    <strong>{user?.email}</strong>{" "}
                  </div>
                  <div className="item">
                    <label>TIPO DE DEFICIÊNCIA</label>
                    <br />
                    <strong>
                      {getDisabilityTypeLabel(user?.disability_type)}
                    </strong>
                  </div>
                  <div className="item">
                    <label>RECURSO ASSISTIVO</label>
                    <br />
                    <strong>
                      {getAssistiveResourceLabel(user?.assistive_resource)}
                    </strong>
                  </div>
                </div>

                <div className="buttons">
                  <Button
                    className="editar"
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button className="logout" onClick={logout}>
                    Sair
                  </Button>
                </div>
              </ModalProfile>
            </>
          )}
        </>
      ) : null}
    </>
  );
}

const modal = {
  Profile: Profile,
  Initial: Initial,
};

export default modal;

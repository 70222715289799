import styled from "styled-components";

export const MapStyle = styled.div`
  .logo {
    position: absolute;
    right: 35px;
    bottom: 22px;
    width: 170px;
    z-index: 2;
  }
  .leaflet-container {
    height: 100vh;
    border-radius: 4px;
    width: 100vw;
    z-index: 0;
  }
  .leaflet-control-layers {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1);
    margin-top: 52px;
    border: none;
  }

  .leaflet-control-zoom {
    margin-top: 60px;
    border: none;
  }

  .leaflet-control-zoom-in {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1);
  }

  .leaflet-control-zoom-out {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1);
  }

  .leaflet-control-zoom-in:hover {
    background-color: rgba(0, 0, 0, 0.6);
    color: rgba(255, 255, 255, 1);
  }

  .leaflet-control-zoom-out:hover {
    background-color: rgba(0, 0, 0, 0.6);
    color: rgba(255, 255, 255, 1);
  }

  @media screen and (max-width: 768px) {
    .leaflet-bar {
      position: fixed;
      bottom: 17px;
    }

    .leaflet-bar .leaflet-control {
      padding-top: 20px;
    }

    .leaflet-control-attribution {
      position: fixed;
      right: 0;
      bottom: 0;
    }
  }
`;

export const LocaleControlStyle = styled.div`
  position: absolute;
  top: 120px;

  cursor: pointer;
  z-index: 1000;

  div {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
  }

  div:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  svg {
    margin: 3px 3px 0px;
  }

  .leaflet-control {
    border: 0;
  }

  @media screen and (max-width: 768px) {
    .leaflet-control {
      margin-bottom: 70px;
    }
  }
`;

import { SetStateAction } from "react";
import { IMessageOptions } from "react-chatbot-kit/build/src/interfaces/IMessages";
import {
  AddressProps,
  ChatbotStateProps,
  CreateMessageReturn,
} from "../config/interfaces";
import checkpoints from "../controllers/map/checkpoints";
import MapStateControl from "../mapStateControl/MapStateControl";
import { i18n } from "../translate/i18n";

class ActionProvider {
  createChatbotMessage: (
    message: string,
    options?: IMessageOptions
  ) => CreateMessageReturn;
  setState: React.Dispatch<SetStateAction<any>>;
  createClientMessage: (
    message: string,
    options?: IMessageOptions
  ) => CreateMessageReturn;
  state: ChatbotStateProps;
  mapControl: MapStateControl;

  constructor(
    createChatbotMessage: (
      message: string,
      options?: IMessageOptions
    ) => CreateMessageReturn,
    setStateFunc: React.Dispatch<SetStateAction<any>>,
    createClientMessage: (
      message: string,
      options?: IMessageOptions
    ) => CreateMessageReturn,
    state: ChatbotStateProps,
    mapControl: MapStateControl
  ) {
    this.createChatbotMessage = createChatbotMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.state = state;
    this.mapControl = mapControl;
  }

  /* GETTERS AND SETTERS */
  getState() {
    return this.state;
  }

  //Initialized Getter and Setter
  getInitialized() {
    return this.state.routeState.initialized;
  }
  setInitialized(initialized: boolean) {
    this.state.routeState.initialized = initialized;
  }

  //Origin Getter and Setter
  getOrigin() {
    return this.state.routeState.origin;
  }
  setOrigin(origin: string) {
    this.state.routeState.origin = origin;
  }

  //Destination Getter and Setter
  getDestination() {
    return this.state.routeState.destination;
  }
  setDestination(destination: string) {
    this.state.routeState.destination = destination;
  }

  //FromCoords Getter and Setter
  getFromCoords() {
    return this.state.routeState.fromCoords;
  }
  setFromCoords(coords: string) {
    this.state.routeState.fromCoords = coords;
  }

  //ToCoords Getter and Setter
  getToCoords() {
    return this.state.routeState.toCoords;
  }
  setToCoords(coords: string) {
    this.state.routeState.toCoords = coords;
  }

  //DisabilityType Getter and Setter
  getDisabilityType() {
    return this.state.routeState.disabilityType;
  }
  setDisabilityType(disabilityType: string) {
    this.state.routeState.disabilityType = disabilityType;
  }

  //AssistiveResource Getter and Setter
  getAssistiveResource() {
    return this.state.routeState.assistiveResource;
  }
  setAssistiveResource(assistiveResource: string) {
    this.state.routeState.assistiveResource = assistiveResource;
  }

  //Transport Getter and Setter
  getTransport() {
    return this.state.routeState.transport;
  }
  setTransport(transport: string) {
    this.state.routeState.transport = transport;
  }

  //FromAddresses Getter and Setter
  getFromAddresses() {
    return this.state.routeState.fromAddresses;
  }
  setFromAddresses(addresses: AddressProps[]) {
    this.state.routeState.fromAddresses = addresses;
  }

  //ToAddresses Getter and Setter
  getToAddresses() {
    return this.state.routeState.toAddresses;
  }
  setToAddresses(addresses: AddressProps[]) {
    this.state.routeState.toAddresses = addresses;
  }

  //LastInteraction Getter and Setter
  getLastInteraction() {
    return this.state.routeState.lastInteraction;
  }
  setLastInteraction(interaction: string) {
    this.state.routeState.lastInteraction = interaction;
  }

  /*WIDGET LAUNCHERS */
  //Lança o widget de start
  startWidgetLauncher() {
    const botMessage = this.createChatbotMessage(
      "Clique em Iniciar para continuar.",
      {
        widget: "start",
      }
    );

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de origin
  originWidgetLauncher() {
    const botMessage = this.createChatbotMessage(
      this.getFromAddresses().length === 0
        ? i18n.t('chatbot.actionProvider.fromToAddresses.foundEmpty')
        : i18n.t('chatbot.actionProvider.fromToAddresses.foundSomething'),
      {
        widget: "fromAddresses",
      }
    );

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de destination
  destinationWidgetLauncher() {
    const botMessage = this.createChatbotMessage(
      this.getToAddresses().length !== 0
        ? i18n.t('chatbot.actionProvider.fromToAddresses.foundSomething')
        : i18n.t('chatbot.actionProvider.fromToAddresses.foundEmpty'),
      {
        widget: "toAddresses",
      }
    );

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de disabilityType
  disabilityTypeWidgetLauncher() {
    const botMessage = this.createChatbotMessage(
      i18n.t('chatbot.actionProvider.disabilityType'),
      {
        widget: "disabilityType",
      }
    );

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de assistiveResource
  assistiveResourceWidgetLauncher() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.assistiveResource'), {
      widget: "assistiveResource",
    });

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de transport
  transportWidgetLauncher() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.transport'), {
      widget: "transports",
    });

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de validation
  validationWidgetLauncher(message: string) {
    const botMessage = this.createChatbotMessage(message, {
      widget: "validation",
    });

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Lança o widget de options
  optionsWidgetLauncher() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.options'), {
      widget: "options",
    });

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  /*HANDLERS */
  //Busca endereços de origem
  async findOrigin(origin: string) {
    let data = await checkpoints.getLocation(origin);
    this.setFromAddresses(data);
  }
  //Busca endereços de destino
  async findDestination(destination: string) {
    let data = await checkpoints.getLocation(destination);
    this.setToAddresses(data);
  }
  //Recebe o identificador da etapa e conduz ao processo seguinte correspondente
  handleValidation(stage: string, option?: boolean) {
    switch (stage) {
      case "start": {
        this.startWidgetLauncher();
        break;
      }
      case "fromAddresses": {
        if (option) {
          this.destinationRequest();
        } else {
          this.setOrigin("");
          this.originWidgetLauncher();
        }
        break;
      }
      case "toAddresses": {
        if (option) {
          this.setInitialized(false);
          this.disabilityTypeWidgetLauncher();
        } else {
          this.setDestination("");
          this.destinationWidgetLauncher();
        }
        break;
      }
      case "disabilityType": {
        if (option) {
          this.assistiveResourceWidgetLauncher();
        } else {
          this.disabilityTypeWidgetLauncher();
        }
        break;
      }
      case "assistiveResource": {
        if (option) {
          this.transportWidgetLauncher();
        } else {
          this.assistiveResourceWidgetLauncher();
        }
        break;
      }
      case "transports": {
        if (option) {
          this.routeRoadmap();
        } else {
          this.transportWidgetLauncher();
        }
        break;
      }
    }
  }
  //Reseta o mapState e o ChatbotState
  handleReset() {
    this.startWidgetLauncher();
    this.mapControl.resetMap();
    this.setState({
      routeState: {
        initialized: false,
        fromAddresses: [],
        toAddresses: [],
        origin: "",
        destination: "",
        fromCoords: "",
        toCoords: "",
        disabilityType: "",
        assistiveResource: "",
        transport: "",
        lastInteraction: "start",
      },
      messages: [
        this.createChatbotMessage(
          i18n.t('chatbot.actionProvider.startMessage'),
          {
            widget: "start",
          }
        ),
      ],
    });
  }

  /*TEXT REQUESTORS */
  //Solicita o endereço de origem
  originRequest() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.fromToAddresses.requests.from'));

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Solicita o endereço de destino
  destinationRequest() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.fromToAddresses.requests.to'));

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Opção padrão de resultados esperado não encontrado na lista
  handleOptionNotFound() {
    const botMessage = this.createChatbotMessage(i18n.t('chatbot.actionProvider.notFound'));

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  //Resposta padrão para mensagens não configuradas
  handleDefault() {
    const botMessage = this.createChatbotMessage(
      i18n.t('chatbot.actionProvider.defaultMessage')
    );

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }
  addMessageToState = (message: string) => {
    this.setState((state: ChatbotStateProps) => ({
      ...state,
      messages: [...state.messages, message],
    }));
  };

  //ROADMAP
  originRoadmap(message: string) {
    this.findOrigin(message);
    setTimeout(() => {
      this.originWidgetLauncher();
    }, 1000);
  }
  destinationRoadmap(message: string) {
    this.findDestination(message);
    setTimeout(() => {
      this.destinationWidgetLauncher();
    }, 500);
  }
  routeRoadmap() {
    this.generateRoute();
    this.optionsWidgetLauncher();
    console.log(this.getState());
  }
  addMarker(coords: string, isStart: boolean) {
    console.log(this.mapControl.mapState);

    let [lat, lng] = coords.split(",");
    this.mapControl.addRouteMakers([parseFloat(lng), parseFloat(lat)], isStart);
  }
  async generateRoute() {
    const botMessage = this.createChatbotMessage(
      i18n.t('chatbot.actionProvider.generateRoute'),
      {
        delay: 500,
      }
    );

    let params = {
      assistive_resource: this.getAssistiveResource(),
      transport: this.getTransport(),
      from_cords: this.getFromCoords(),
      to_cords: this.getToCoords(),
    };

    this.setState((prev: ChatbotStateProps) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));

    this.mapControl.setRouter(await checkpoints.getRoute(params));
  }
}

export default ActionProvider;

const messages = {
  pt: {
    translations: {
      modal: {
        initial: {
          title: "Rotas Acessíveis Customizadas",
          sub: "É fácil de usar:",
          s1: "Escolha a origem e o destino",
          s2: "Selecione o Tipo de Deficência",
          s3: "Selecione o Recurso Assistivo",
          s4: "Escolha o Modo de Deslocamento",
          s5: "Gere a rota",
        },
        location: {
          search: "Informe o local ",
          current: "Usar localização atual",
          button: "Pesquisar",
        },
      },
      bar: {
        router: {
          from: "De: ",
          to: "Para: ",
          disabilityTypeList: "Tipo de Deficiência",
          assistiveResource: "Recurso Assistivo",
          transport: "Transporte",
          clearButtom: "Limpar",
          goButtom: "Ir",
        },
      },
      legend: {
        classification: "Classificação",
        favourable: "Favorável",
        acceptable: "Aceitável",
        critical: "Crítico",
        nps: {
          title: "Escreva sua avaliação",
          sub: "Conte-nos sua experiência na plataforma",
          send: "Enviar",
          question:
            "Em uma escala de 0 a 10, o quanto você indicaria esta plataforma para outas pessoas?",
        },
      },
      consts: {
        transportList: {
          walking: "A pé",
          driving: "De carro",
          bike: "De bicicleta",
          public: "Transporte público",
          taxi: "Táxi",
          app: "Transporte por aplicativo",
        },
        disabilityTypeList: {
          cognitive: "Cognitiva",
          hearing: "Auditiva",
          physical: "Física",
          visual: "Visual",
          other: "Não Possuo",
        },
        assistiveResourceList: {
          crutch: "Muleta",
          guideDog: "Cão-guia",
          hearingAid: "Aparelho Auditivo",
          llp: "Prótese Membro Inferior",
          ulp: "Prótese Membro Superior",
          walker: "Andador",
          walkingStick: "Bengala",
          wheelchair: "Cadeira de Rodas",
          other: "Não Possuo",
        },
        tourConfig: {
          from: "Escolha a origem",
          to: "Escolha o destino",
          disabilityType: "Selecione o tipo de deficiência",
          assistiveResource: "Selecione o Recurso Assistivo",
          transport: "Selecione o meio de locomoção",
          goButtom: "Gere a rota",
          nextButton: "Avançar",
          prevButton: "Voltar",
          welcome: "Olá, eu sou a Izzi e vou mostrar para vocês como é fácil usar nossa plataforma",
          chatbot: "Se preferir, pode me chamar por aqui"
        },
      },
      httpResponses: {
        searching: {
          in: "Pesquisando locais",
          sucess: "Locais obtidos com sucesso",
          fail: {
            general: "Falha ao pesquisar locais",
            nodata: "Nenhum local correspondente",
          },
        },
        getrouter: {
          in: "Obtendo melhor rota",
          sucess: "Rota obtida com sucesso",
          fail: {
            general: "Falha ao obter rota",
          },
        },
        nps: {
          in: "Sending review",
          sucess: "Thanks for the review!",
          fail: {
            general: "Failed to submit",
          },
        },
      },
      chatbot: {
        getAccess: 'Clique aqui para acessar o chatbot!',
        conective: 'com',
        type: 'Digite aqui.',
        actionProvider: {
          fromToAddresses: {
            requests: {
              from: "De onde você vai sair? ",
              to: "Para onde você vai? "
            },
            foundEmpty: "Não encontrei resultados para essa pesquisa",
            foundSomething: "Selecione o endereço abaixo: "
          },
          disabilityType: "Qual o tipo de deficiência?",
          assistiveResource: "Qual o recurso assistivo?",
          transport: "Como você deseja ir?",
          options: 'Selecione uma opção: ',
          startMessage: "Vamos gerar uma nova rota personalizada? Clique em Iniciar!",
          notFound: "Então digite novamente ",
          defaultMessage: "Minhas respostas são limitadas. Insira as opções sugeridas.",
          generateRoute: "As informações da sua rota foram exibidas no mapa! Fique atento!",
        },
        configMessages: {
          createChat: "Olá, sou a Izzi e vou indicar a melhor rota para você.",
          startCreateChat: "Clique em Iniciar para continuar e gerar sua rota!"
        },
        widgets: {
          start: 'Iniciar',
          submitConfirmation: 'está certo?',
          sure: 'tem certeza?',
          tryAgain: 'Tente novamente',
          confirm: {
            yes: 'Sim',
            no: 'Não',
          },
          confirmRoute: {
            confirm: 'Confirmar',
            return: 'Voltar',
            origin: 'Origem:',
            destination: 'Destino:',
            disabilityType: 'Tipo de deficiência:',
            assistiveResource: 'Recurso Assistivo:',
            transport: 'Modo de deslocamento:'
          },
          placesMessages: {
            isThere: "É esse lugar mesmo?",
            didntFound: "Endereço não listado",
            destiny: "Esse é o seu destino?",
          }, 
          generateRoute: {
            anotherRoute: 'Gerar outra rota',
            anotherOption: 'Outra opção',
          }
        }
      },
      
    },
  },
};

export { messages };

import styled from "styled-components";

export const CloseSection = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const NpsForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 25vh;
  right: 20vw;
  bottom: auto;
  left: 20vw;
  padding: 20px;
  box-shadow: 10px 20px 40px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;

  h1 {
    font-family: "Poppins", sans-serif;
  }

  span {
    margin-bottom: 20px;
    font-family: "DM Sans", sans-serif;
  }

  ul {
    display: flex;
    list-style-type: none;
    margin: 10px 5px 20px 5px;

    li {
      margin: 0 2px;

      input {
        display: none;
        :checked + label {
          opacity: 0.4;
        }
      }

      label {
        align-items: center;
        padding: 5px 15px 3px 15px;
        font-weight: bold;
        cursor: pointer;

        :hover {
          opacity: 0.5;
        }
      }
    }
  }

  textarea {
    width: 70%;
    height: 80px;
    padding: 5px;
    margin-bottom: 20px;
    font-family: "DM Sans", sans-serif;
    border-radius: 5px;
    border: none;
    background-color: rgba(216, 222, 233, 1);
    color: #2a2727;
    font-size: 14px;
    outline: none;
    resize: none;
  }

  .alert {
    color: #ba0707;
    font-size: 14px;
  }

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    fill: #5a5a5a;
    cursor: pointer;

    :hover {
      fill: rgba(255, 0, 0, 1);
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 15vh;
    left: 5vw;
    right: 5vw;
    bottom: auto;

    h1 {
      text-align: center;
      line-height: 1;
      margin-bottom: 15px;
    }

    ul {
      li {
        label {
          padding: 4px 8px 4px 8px;
          font-size: 14px;
        }
      }
    }

    textarea {
      width: 90%;
      margin-bottom: 30px;
      margin-top: 15px;
    }
  }
`;

import React from "react";

const BotAvatar = () => {
    return (
        <>
            <img
                style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    marginRight: "10px",
                    marginLeft: "-10px",
                }}
                src="/images/bot_profile_call_center.jpg"
                alt=""
            />
        </>
    );
};

export { BotAvatar };

import TourScreen from "../AppComponents/components/tourScreen";
import { i18n } from "../translate/i18n";
export const tourConfig = () => {
  let tourConfigBase = [
    {
      selector: "",
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/IZZI_1.png"
            altImage="Mascote desejando boas vindas e iniciando o tutorial do uso da plataforma"
            message={i18n.t("consts.tourConfig.welcome")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__from"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose02.png"
            altImage="Mascote mostrando o local de inserção da origem"
            message={i18n.t("consts.tourConfig.from")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__to"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose01.png"
            altImage="Mascote mostrando o local de inserção de destino"
            message={i18n.t("consts.tourConfig.to")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__disabilityType"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose03.png"
            altImage="Mascote mostrando o local de inserção do tipo de deficiência"
            message={i18n.t("consts.tourConfig.disabilityType")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__assistiveResource"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose02.png"
            altImage="Mascote mostrando o local de inserção do recurso assistivo"
            message={i18n.t("consts.tourConfig.assistiveResource")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__transportList"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose01.png"
            altImage="Mascote mostrando o local de inserção do meio de transporte"
            message={i18n.t("consts.tourConfig.transport")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__goButtom"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/pose03.png"
            altImage="Mascote mostrando o botão de geração de rota"
            message={i18n.t("consts.tourConfig.goButtom")}
          />
        );
      },
    },
    {
      selector: '[data-tut="reactour__chatbot"]',
      content: () => {
        return (
          <TourScreen
            sourceImage="/images/IZZI_3.png"
            altImage="Mascote mostrando o botão de chatbot"
            message={i18n.t("consts.tourConfig.chatbot")}
          />
        );
      },
    },
  ];

  return tourConfigBase;
};

import api from "../../services/api";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import { FieldValues } from "react-hook-form";
import { LoginDataProps } from "../../config/interfaces";

import { i18n } from "../../translate/i18n";
class Auth {
  //Fluxograma tutorial/cadastro/login
  clearDataStorage = () => {
    localStorage.removeItem("izzistrit_data_session");
  };
  setDataStore = (params: boolean) => {
    localStorage.setItem("alreadyKnows", JSON.stringify(params));
  };
  getDataStorage = () => {
    let requestResult = localStorage.getItem("alreadyKnows");
    if (requestResult) {
      return true;
    } else {
      return false;
    }
  };

  nps = async (params: { value: number }) => {
    try {
      await toast.promise(api.post("/nps", params), {
        pending: i18n.t("httpResponses.nps.in") as string,
        success: {
          render() {
            return i18n.t("httpResponses.nps.sucess");
          },
          autoClose: 700,
        },
        error: i18n.t("httpResponses.nps.fail.general") as string,
      });
    } catch (error) {
      return error;
    }
  };

  /**
   * @param params Dados de login ({email, senha} || {userId})
   * @param navigate Função de controle do modal
   * @returns Status autenticado
   */
  login = async (params: FieldValues, navigate?: Function) => {
    try {
      const { data } = await api.post("/login", params);
      this.saveToken(data.token);

      navigate && navigate("/");
    } catch (error) {
      throw error;
    }
  };

  /**
   * @param params Dados para cadastro do usuário (userId, nome, email, senha, idade, tipo de deficiência, recurso assistivo, permitir atualizações)
   * @param navigate Função de controle do modal
   * @returns Cadastro no sistema
   */
  register = async (params: FieldValues, navigate?: Function) => {
    try {
      await api.post("/users", params);

      navigate && navigate("/");
    } catch (error) {
      throw error;
    }
  };

  /**
   * @param params Dados para atualizar perfil do usuário (nome, tipo de deficiência, recurso assistivo, imagem do perfil)
   * @param navigate Função de controle do modal
   * @returns Atualização das informações do perfil
   */
  update = async (params: FieldValues, navigate?: Function) => {
    try {
      const { data } = await api.patch("/users", params);
      this.saveToken(data.token);

      navigate && navigate("/");
    } catch (error) {
      throw error;
    }
  };

  /**
   * Salva o token de autenticação
   * @param cvalue Token de autenticação
   */
  saveToken = (cvalue: string) => {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = "SLI-SF=" + cvalue + ";" + expires + ";path=/";
  };

  /**
   * @returns Token de autenticação
   */
  getToken = () => {
    let name = "SLI-SF=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  /**
   * @param options Dados do usuário autenticado (informações do perfil, transport, coordenadas de origem e destino)
   * @returns Dados do usuário autenticado + dados de entrada para gerar rota (recurso assistivo, transport, origem, destino)
   */
  getHeaders = (options: {
    params: { profile?: string; transport: string; lonlats: string };
  }) => {
    return {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
      ...options,
    };
  };

  /**
   * Expira o token e finaliza a sessão
   * @param navigate Função de controle do modal
   */
  logout = async (navigate?: Function) => {
    document.cookie = "SLI-SF=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    navigate && navigate();
    const resolveAfter700mi = new Promise((resolve) =>
      setTimeout(resolve, 500)
    );
    try {
      await toast.promise(resolveAfter700mi, {
        pending: "Realizando logout",
        success: {
          render() {
            return "Logout efetuado";
          },
          autoClose: 700,
        },
        error: "Falha ao sair do sistema.",
      });

      navigate && navigate("/");
    } catch (error) {
      return error;
    }
  };

  /**
   * @param token Token de autenticação
   * @returns Dados do usuário autenticado
   */
  getUser = (token = "") => {
    try {
      const jwtToken = token ? token : this.getToken();
      const decoded: {
        name?: string;
        email?: string;
        disability_type?: string;
        assistive_resource?: string;
        profile_image?: string;
      } = jwtToken ? jwt(jwtToken.replace("Bearer", "")) : {};
      return decoded;
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @param token Token de autenticação
   * @returns Nome e sobrenome do usuário abreviados se necessário
   */
  getAbrName = (token = "") => {
    try {
      const jwtToken = token ? token : this.getToken();
      const user: {
        assistive_resource: string;
        disability_type: string;
        email: string;
        exp: Number;
        iat: Number;
        id: Number;
        name: string;
      } = jwt(jwtToken.replace("Bearer", ""));

      const [nome, ...sobrenomes] = user.name.split(" ");

      const abreviaturas =
        sobrenomes.length !== 0
          ? sobrenomes.reduce((arr: string, str) => {
              const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
              if (!letraGrande) {
                return arr;
              }
              return arr.concat(` ${letraGrande[0]}.`);
            })
          : "";

      return [nome, abreviaturas].join(" ");
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @returns Status da sessão
   */
  isAuthenticated = () => {
    const token = this.getToken();
    if (token !== "") {
      return true;
    }
    return false;
  };
}

export default new Auth();

import { OverridableTokenClientConfig } from "@react-oauth/google";
import { RegisterFormStyled } from "./styles";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import {
  AssistiveResourceProps,
  InputRegistrationProps,
} from "../../../../../../config/interfaces";
import {
  disabilityTypeList,
  getAssistiveResource,
} from "../../../../../../config/constants";
import { useState } from "react";
import Button from "../Button";

interface RegisterFormCardProps {
  registerForm: UseFormReturn<InputRegistrationProps, any>;
  onSubmitRegistration: SubmitHandler<InputRegistrationProps>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  googleRegister: (
    overrideConfig?: OverridableTokenClientConfig | undefined
  ) => void;
  handleClose: Function;
}

const RegisterFormCard = (props: RegisterFormCardProps) => {
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [assistiveResources, setAssistiveResources] = useState<
    Array<AssistiveResourceProps>
  >([
    {
      id: 0,
      label: "",
      value: "",
    },
  ]);

  return (
    <RegisterFormStyled>
      <div className="topContent">
        <h1>Quer fazer parte da nossa rede?</h1>
        <p>Cadastre-se para receber nossas atualizações!</p>
      </div>
      <form
        onSubmit={props.registerForm.handleSubmit(props.onSubmitRegistration)}
        className="middleContent"
      >
        <div className="leftMiddleContent">
          <label>
            NOME COMPLETO*
            {props.registerForm.formState.errors.name && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.registerForm.register("name", { required: true })}
            type="text"
            name="name"
            id="name"
          />
          <label>
            EMAIL*
            {props.registerForm.formState.errors.email && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.registerForm.register("email", { required: true })}
            type="email"
            name="email"
            id="email"
          />
          <label>IDADE</label>
          <input
            {...props.registerForm.register("age")}
            type="number"
            min={0}
            max={125}
            step={1}
            name="age"
            id="age"
          />

          <label>
            SENHA*
            {confirmPassword !== props.registerForm.getValues("password") &&
            confirmPassword !== "" ? (
              <span className="alertMessage">
                <img src="/icons/alert.svg" alt="alert icon" />
                <p>As senhas não coincidem</p>
              </span>
            ) : null}
            {props.registerForm.formState.errors.password && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.registerForm.register("password", { required: true })}
            type="password"
            id="password"
          />
          <label>
            CONFIRMAR SENHA*
            {props.registerForm.formState.errors.confirm_password && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <input
            {...props.registerForm.register("confirm_password", {
              required: true,
            })}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type="password"
            id="confirmPassword"
          />
        </div>
        <div className="rightMiddleContent">
          <label>
            TIPO DE DEFICIÊNCIA
            {props.registerForm.formState.errors.disability_type && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <select
            {...props.registerForm.register("disability_type")}
            name="disability_type"
            onChange={(e) =>
              setAssistiveResources(getAssistiveResource(e.target.value))
            }
            id="disabilityType"
          >
            <option value="0">Selecionar</option>
            {disabilityTypeList.map((element, index) => {
              return (
                <>
                  <option key={index} value={element.value}>
                    {element.label}
                  </option>
                </>
              );
            })}
          </select>
          <label>
            RECURSO ASSISTIVO
            {props.registerForm.formState.errors.assistive_resource && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
          </label>
          <select
            {...props.registerForm.register("assistive_resource")}
            name="assistive_resource"
            id="assistiveResource"
          >
            <option value="0">Selecionar</option>
            {assistiveResources.map((element, index) => {
              return (
                <>
                  <option key={index} value={element.value}>
                    {element.label}
                  </option>
                </>
              );
            })}
          </select>
          <div className="acceptSection">
            {props.registerForm.formState.errors.accept_terms && (
              <>
                <span className="alertMessage">
                  <img src="/icons/alert.svg" alt="alert icon" />
                  <p>Campo Obrigatório</p>
                </span>
              </>
            )}
            <div>
              <input
                {...props.registerForm.register("accept_terms", {
                  required: true,
                })}
                type="checkbox"
                id="terms-checkbox"
              />
              <label htmlFor="terms-checkbox">
                *Autorizo a Plataforma Izzistrit a utilizar meus dados para
                melhorar minha experiência de acordo com os{" "}
                <a
                  href="http://izzistrit.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Termos e Política de Privacidade
                </a>
              </label>
            </div>

            <div>
              <input
                {...props.registerForm.register("allow_updates")}
                type="checkbox"
                name="allowUpdates"
                id="update-checkbox"
              />
              <label htmlFor="update-checkbox">
                Deseja receber atualizações e notícias da Plataforma via email?
              </label>
            </div>
          </div>
          <Button
            title={"Cadastrar"}
            functionRef={() => {}}
            backgroundColor={"rgba(46, 52, 64, 1)"}
            color={"#ffffff"}
          />
        </div>
      </form>
      <div className="bottomContent">
        <div>
          <p className="titleSocialLogin">SE PREFERIR</p>
          <Button
            title={"Cadastrar com Google"}
            image={"/icons/google-icon.svg"}
            functionRef={props.googleRegister}
            color={"rgba(255, 75, 38, 0.8)"}
            backgroundColor={"rgba(216, 222, 233, 1)"}
          />
        </div>
        <p className="loginLink" onClick={() => props.setIsLogin(true)}>
          Tenho uma conta
        </p>
      </div>
    </RegisterFormStyled>
  );
};

export default RegisterFormCard;

//chatbot default imports
import Chatbot from "react-chatbot-kit";
import styled from "styled-components";
import config from "../chatbot/config";
import "react-chatbot-kit/build/main.css";
import messageParser from "../chatbot/messageParser";
import actionProvider from "../chatbot/actionProvider";

import MapStateControl from "../mapStateControl/MapStateControl";
import { ChatbotStateProps, CreateMessageReturn } from "../config/interfaces";
import { IMessageOptions } from "react-chatbot-kit/build/src/interfaces/IMessages";
import { SetStateAction } from "react";
import { i18n } from "../translate/i18n";

export default function ChatbotWindow({
  mapControl,
  ...params
}: {
  mapControl: MapStateControl;
  handleDisplay: boolean;
  handleClose: () => void;
}) {
  class newActionProvider extends actionProvider {
    constructor(
      createChatbotMessage: (
        message: string,
        options?: IMessageOptions
      ) => CreateMessageReturn,
      setStateFunc: React.Dispatch<SetStateAction<any>>,
      createClientMessage: (
        message: string,
        options?: IMessageOptions
      ) => CreateMessageReturn,
      state: ChatbotStateProps
    ) {
      super(
        createChatbotMessage,
        setStateFunc,
        createClientMessage,
        state,
        mapControl
      );
    }
  }
  return (
    <>
      <div
        onClick={() => params.handleClose()}
        style={{
          display: params.handleDisplay ? "flex" : "none",
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%",
        }}
      ></div>
      <CustomWindow
        style={{
          display: params.handleDisplay ? "flex" : "none",
        }}
      >
        <Chatbot
          config={config}
          messageParser={messageParser}
          actionProvider={newActionProvider}
          placeholderText={i18n.t('chatbot.type') as string}
        />
      </CustomWindow>
    </>
  );
}

const CustomWindow = styled.div`
  position: fixed;
  top: 52px;
  left: 10px;

  @media screen and (max-width: 768px) {
    top: auto;
    bottom: 17px;
  }
`;

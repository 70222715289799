import {
  ChildComponentRef,
  CustomComponent,
} from "../../../../config/interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";
import { AddressProps } from "../../../../config/interfaces";
import checkpoints from "../../../../controllers/map/checkpoints";
import "./styles.css";

const SelectizeComponent = forwardRef<ChildComponentRef, CustomComponent>(
  (
    {
      dataTarget,
      className,
      id,
      label,
      previusData,
      customStyle,
      error,
      parentCallback,
    }: CustomComponent,
    ref
  ) => {
    const [options, set_options] = useState<Array<AddressProps>>([]);

    async function input_value_changed(id: string) {
      const elemento = document.getElementById(id);

      if (elemento && !elemento.classList.contains("changed")) {
        elemento.classList.add("changed");
        setTimeout(() => handle_get_options(id), 1000);
      }
    }

    async function handle_get_options(id: string) {
      const elemento = document.getElementById(id);

      if (elemento && elemento.classList.contains("changed")) {
        const inputField = document.querySelector(
          "#" + id + " .selectizeHeader input"
        );
        let data = "";
        if (inputField instanceof HTMLInputElement) {
          data = inputField.value;
        }
        elemento.classList.remove("changed");
        let result = await checkpoints.getLocation(data);
        //const result = await checkpoints.getLocationWithoutToast(
        //  data,
        //  previusData?.userLatitude,
        //  previusData?.userLongitude
        //);
        const dropDown = document.querySelector("#" + id + " .dropDown");
        const selectizeHeader = document.querySelector(
          "#" + id + " .selectizeHeader"
        );
        set_options(result);

        if (result.length) {
          if (dropDown instanceof HTMLElement) {
            dropDown.style.display = "block";
          }
          if (selectizeHeader instanceof HTMLElement) {
            selectizeHeader.style.borderRadius = "15px 15px 0px 0px";
          }
        } else {
          if (dropDown instanceof HTMLElement) {
            dropDown.style.display = "none";
          }

          if (selectizeHeader instanceof HTMLElement) {
            selectizeHeader.style.borderRadius = "15px";
          }
        }
      }
    }

    function hide_dropDown_options(id: string) {
      const dropDown = document.querySelector("#" + id + " .dropDown");
      const selectizeHeader = document.querySelector(
        "#" + id + " .selectizeHeader"
      );

      if (dropDown instanceof HTMLElement) {
        dropDown.style.display = "none";
      }

      if (selectizeHeader instanceof HTMLElement) {
        selectizeHeader.style.borderRadius = "15px";
      }
    }

    function show_dropDown_options(id: string) {
      const dropDown = document.querySelector("#" + id + " .dropDown");
      const selectizeHeader = document.querySelector(
        "#" + id + " .selectizeHeader"
      );
      if (options.length) {
        if (dropDown instanceof HTMLElement) {
          dropDown.style.display = "block";
        }
        if (selectizeHeader instanceof HTMLElement) {
          selectizeHeader.style.borderRadius = "15px 15px 0px 0px";
        }
      }
    }

    function selectize_option(id: string, option: AddressProps) {
      const inputField = document.querySelector(
        "#" + id + " .selectizeHeader input"
      );

      if (inputField instanceof HTMLInputElement) {
        inputField.value = option.display_name;
      }

      if (parentCallback) {
        parentCallback(option);
      }
      hide_dropDown_options(id);
    }

    function reset_value(id: string) {
      const inputField = document.querySelector(
        "#" + id + " .selectizeHeader input"
      );

      if (inputField instanceof HTMLInputElement) {
        inputField.value = "";
        set_options([]);
      }
    }

    useImperativeHandle(ref, () => ({
      reset_value,
    }));

    const [locationPermission, setLocationPermission] = useState(false);

    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        setLocationPermission(true);
      }
    });

    return (
      <div
        data-tut={dataTarget}
        className={className + " custom-selectize"}
        id={id}
        style={{ width: customStyle?.width ? customStyle.width : "100%" }}
      >
        <div
          className={
            "selectizeHeader " + (customStyle?.disable ? "disable" : "")
          }
          style={{
            border: error ? "1px solid red" : "none",
          }}
        >
          <input
            type="text"
            name={id}
            autoComplete="off"
            disabled={customStyle?.disable}
            placeholder={label}
            defaultValue={
              id === "fromSelectize" && locationPermission
                ? "Localização atual"
                : undefined
            }
            onFocus={() =>
              customStyle?.disable ? null : show_dropDown_options(id)
            }
            onBlur={() =>
              customStyle?.disable ? null : hide_dropDown_options(id)
            }
            onChange={(e) => input_value_changed(id)}
          />
          {options.length !== 0 ? (
            <div className="selectize_arrow"> </div>
          ) : null}
        </div>

        <div className="dropDown" style={{ overflow: "auto" }}>
          <div className="listDropDown">
            {options.length !== 0 ? (
              options.map((option, index) => {
                return (
                  <option
                    className="item"
                    key={index}
                    onMouseDown={() => selectize_option(id, option)}
                  >
                    {option.display_name}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default SelectizeComponent;

import styled from "styled-components";

export const RegisterFormStyled = styled.div`
  width: 100%;
  .topContent {
    h1 {
      font-family: "Poppins", sans-serif;
      line-height: 1;
      font-size: 40px;
      color: #2a2727;
    }

    p {
      margin-bottom: 20px;
      font-weight: bold;
      font-family: "DM Sans", sans-serif;
      color: #5a5a5a;
    }
  }

  .middleContent {
    display: flex;
    width: 100%;

    .alertMessage {
      display: flex;
      align-items: center;
      margin-left: 5px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
      font-size: 14px;
      color: red;

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }

    label {
      display: flex;
      align-items: center;
      font-family: "DM Sans", sans-serif;
    }

    input[type="email"],
    input[type="password"],
    input[type="number"],
    input[type="text"],
    select {
      width: 80%;
      height: 40px;
      margin: 5px 0px 10px 0px;
      padding: 0px 5px;
      border: none;
      border-radius: 5px;
      outline: none;
      font-size: 16px;
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      color: #2a2727;
      background-color: rgba(216, 222, 233, 1);
    }
    select {
      width: calc(80% + 10px);
      cursor: pointer;
    }

    .leftMiddleContent {
      width: 50%;
    }

    .rightMiddleContent {
      width: 50%;
      .acceptSection {
        display: flex;
        flex-direction: column;
        max-width: 80%;
        margin: 10px 0px;
        font-family: "DM Sans", sans-serif;
        color: #2a2727;

        div {
          display: flex;
          align-items: baseline;
        }
        span {
          margin-left: 0;
        }

        div:first-child {
          margin-bottom: 10px;
        }

        input[type="checkbox"] {
          margin-right: 5px;
          cursor: pointer;
        }

        label {
          display: block;
          cursor: pointer;
        }

        a {
          font-family: "DM Sans", sans-serif;
          color: #2a2727;
        }
      }

      Button {
        margin: 30px 20% 0 auto;
      }
    }
  }

  .bottomContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleSocialLogin {
      margin: 20px 0px 15px 0px;
      font-family: "DM Sans", sans-serif;
    }
    .loginLink {
      margin-right: 10%;
      text-decoration: underline;
      color: rgba(46, 52, 64, 1);
      font-family: "DM Sans", sans-serif;
      cursor: pointer;

      :hover {
        color: rgba(46, 52, 64, 0.8);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .topContent {
      h1 {
        font-size: 24px;
        text-align: center;
      }

      p {
        font-size: 14px;
        text-align: center;
      }
    }

    .middleContent {
      flex-direction: column;

      label {
        font-size: 14px;
      }

      select,
      input[type="text"],
      input[type="email"],
      input[type="Password"],
      input[type="number"] {
        width: 100%;
        height: 40px;
        border: none;
        border-radius: 5px;
        outline: none;
      }
      select {
        width: calc(100% + 10px);
        cursor: pointer;
      }

      .leftMiddleContent {
        width: 100%;
      }

      .rightMiddleContent {
        width: 100%;
        .acceptSection {
          max-width: 100%;

          div {
            align-items: start;
          }
          input[type="checkbox"] {
            margin-top: 0px;
          }
        }

        Button {
          margin: 20px auto 0 auto;
        }
      }
    }

    .bottomContent {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 20px;

      .titleSocialLogin {
        margin: 20px 0px 5px 0px;
        text-align: center;
        font-size: 14px;
      }
      Button {
        margin: 0;
      }
      .loginLink {
        display: flex;
        align-self: flex-end;
        margin-right: 0;
        font-size: 14px;
      }
    }
  }
`;

import React from "react";
import { Input } from "./styles";

const InputRadio = (props: { submit: Function, label: string, value: string, index: number }) => {

    return <>
        <div className="inputField" key={props.value}>
            <Input
                type="radio"
                name="option"
                id={props.value}
                value={props.value}
                onChange={(e) => props.submit(props.label, props.value)}
            />
            <label>
                {`${props.index + 1}. ${props.label}`}
            </label>
        </div>
    </>;
};

export default InputRadio;
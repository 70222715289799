import styled, { createGlobalStyle } from "styled-components";
import { Button } from "../components/buttons/buttonStyles";

export const Global = createGlobalStyle`
  *{
       margin: 0px;
       padding: 0px; 
       font-family: Montserrat, sans-serif;
       font-style: normal;
    }
`;

export const BarStyle = styled.div`
  .fake_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    background-color: lightblue;
    background: linear-gradient(rgba(0, 0, 0, 0.6), transparent);
    z-index: -1;
  }
  position: fixed;
  width: 100vw;
  left: 0px;
  top: 0px;
  padding: 0px 5px 0px 0px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
    margin-left: 2px;
  }

  img {
    height: 35px;
    margin: 5px 0px 10px 5px;
    cursor: pointer;
  }

  .headerContent {
    width: 100%;
    justify-content: center;
  }

  svg {
    cursor: pointer;

    height: 25px;
    width: 25px;

    fill: rgba(255, 255, 255, 1);
    padding: 0px 8px;
    :hover {
      fill: rgba(255, 255, 255, 0.8);
    }
  }

  .align_left {
    display: flex;
  }

  .align_right {
    display: flex;
  }

  .transport_select {
    min-width: 225px;
  }

  .namebox {
    color: #fff;
  }

  .perfil {
    padding-top: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    background: linear-gradient(rgba(0, 0, 0, 0.8), transparent);

    img {
      height: 30px;
      margin: 0px 0px 0px 5px;
    }

    .headerContent {
      display: inline;
      position: absolute;
      top: 47px;
      width: 97vw;

      padding-top: 10px;
      padding-bottom: 10px;
      margin-left: 6px;

      border-radius: 10px;
      background: rgba(0, 0, 0, 0.3);
    }

    .account {
      position: relative;
      margin-right: 0;
      Button {
        background: rgba(0, 0, 0, 0);

        padding: 5px;
        border-radius: 10px;
        font-size: 14px;
        margin-right: 0;
      }
    }
  }
`;

export const LocationForm = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 0px 15px;

  ${Button} {
    max-width: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .InputComponent {
    max-width: 190px;
  }

  .InputComponent input {
    height: 32px;
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 1);

    border-radius: 15px;

    font-size: 14px;
    font-weight: 500;
  }

  .InputComponent input:focus {
    color: rgba(255, 255, 255, 1);
    outline: 0;
  }

  .InputComponent input::placeholder {
    color: rgba(255, 255, 255, 1);
  }

  .InputComponent input:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  @media screen and (max-width: 768px) {
    z-index: 1001;
    width: auto;

    display: grid;
    position: relative;

    justify-content: center;
    margin: 0px 5px;

    Button {
      align-self: center;
      margin: auto;
      margin-top: 30px;
    }

    .InputComponent {
      min-width: 270px;
    }

    .InputComponent Select {
      margin-top: 10px;
    }

    .InputComponent Input {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
`;

import { InputBox, NoResultsMessage } from "./styles";
import InputRadio from "../../components/InputRadio";
import { ChatbotProps } from "../../../config/interfaces";
import { i18n } from "../../../translate/i18n";

const Addresses = (props: ChatbotProps) => {
    let addressDestination = props.actionProvider.getToAddresses();

    function handleSubmit(destination: string, coords: string) {
        props.actionProvider.setToCoords(coords);
        props.actionProvider.addMarker(coords, false);
        props.actionProvider.setDestination(destination);
        props.actionProvider.setLastInteraction("toAddresses");
        props.actionProvider.validationWidgetLauncher(i18n.t('chatbot.widgets.placesMessages.destiny'));
    }

    function handleOptionNotFound() {
        props.actionProvider.setDestination("");
        props.actionProvider.handleOptionNotFound();
    }

    function withoutResults() {
        return (<NoResultsMessage>
            <label>{i18n.t('chatbot.widgets.tryAgain')}</label>
        </NoResultsMessage>);
    }

    function withResults() {
        return (<InputBox>
            <form>
                <InputRadio
                    submit={handleOptionNotFound}
                    label={i18n.t('chatbot.widgets.placesMessages.didntFound')}
                    value={""}
                    index={-1}
                />
                {
                    addressDestination.map((element, index) => {
                        return (
                            <InputRadio
                                key={index}
                                submit={handleSubmit}
                                label={element.display_name}
                                value={element.lon + "," + element.lat}
                                index={index}
                            />
                        );
                    })
                }
            </form>
        </InputBox>);
    }

    return addressDestination.length !== 0 ? (
        withResults()
    ) : (
        withoutResults()
    )
};

export default Addresses;
import {
  ChildComponentRef,
  CustomSelectProps,
} from "../../../../config/interfaces";
import { forwardRef, useImperativeHandle } from "react";
import "./styles.css";

const CustomSelectComponent = forwardRef<ChildComponentRef, CustomSelectProps>(
  (
    {
      dataTarget,
      className,
      id,
      label,
      customStyle,
      error,
      options,
      parentCallback,
      register,
    }: CustomSelectProps,
    ref
  ) => {
    function show_dropDown_options(id: string) {
      const dropDown = document.querySelector("#" + id + " .dropDown");
      const selectHeader = document.querySelector("#" + id + " .selectHeader");

      if (dropDown instanceof HTMLElement) {
        dropDown.style.display = "block";
      }
      if (selectHeader instanceof HTMLElement) {
        selectHeader.style.borderRadius = "15px 15px 0px 0px";
      }
    }

    function hide_dropDown_options(id: string) {
      const dropDown = document.querySelector("#" + id + " .dropDown");
      const selectHeader = document.querySelector("#" + id + " .selectHeader");

      if (dropDown instanceof HTMLElement) {
        dropDown.style.display = "none";
      }

      if (selectHeader instanceof HTMLElement) {
        selectHeader.style.borderRadius = "15px";
      }
    }

    function select_value(id: string, value: string, label: string) {
      const inputField = document.querySelector(
        "#" + id + " .selectHeader input"
      );

      if (inputField instanceof HTMLInputElement) {
        inputField.value = label;
      }

      if (parentCallback) {
        parentCallback(value);
      }
      hide_dropDown_options(id);
    }

    function reset_value(id: string) {
      const inputField = document.querySelector(
        "#" + id + " .selectHeader input"
      );

      if (inputField instanceof HTMLInputElement) {
        inputField.value = "";
      }
    }

    useImperativeHandle(ref, () => ({
      reset_value,
    }));
    return (
      <div
        data-tut={dataTarget}
        className={className + " custom-select"}
        id={id}
        style={{ width: customStyle?.width ? customStyle.width : "100%" }}
      >
        <div
          className={"selectHeader " + (customStyle?.disable ? "disable" : "")}
          style={{
            border: error ? "1px solid red" : "none",
          }}
        >
          <input
            type="text"
            name={id}
            autoComplete="off"
            disabled={customStyle?.disable}
            placeholder={label}
            onFocus={() =>
              customStyle?.disable ? null : show_dropDown_options(id)
            }
            onBlur={() =>
              customStyle?.disable ? null : hide_dropDown_options(id)
            }
            {...register}
          />
          <div className="select_arrow"> </div>
        </div>

        <div className="dropDown" style={{ overflow: "auto" }}>
          <div className="listDropDown">
            {options.length != 0 ? (
              options.map((option, index) => {
                return (
                  <option
                    className="item"
                    value={option.value}
                    key={index}
                    onMouseDown={() =>
                      select_value(id, option.value, option.label)
                    }
                  >
                    {option.label}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default CustomSelectComponent;

import React from "react";
import { ButtonContainer } from "./styles";

const Button = (props: {
  title: string;
  image?: string;
  functionRef: Function;
  color: string;
  backgroundColor: string;
}) => {
  return (
    <ButtonContainer
      $color={props.color}
      $backgroundColor={props.backgroundColor}
      onClick={() => props.functionRef()}
    >
      {props.image && <img src={props.image} alt="google" />}
      <p>{props.title}</p>
    </ButtonContainer>
  );
};

export default Button;

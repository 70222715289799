import api from "../../services/api";

import { toast } from "react-toastify";
import auth from "../user/auth";

import {
  AddressProps,
  GetRouteProps,
  GoogleAddressProps,
} from "../../config/interfaces";

import { i18n } from "../../translate/i18n";
import axios from "axios";

class CheckPoints {
  /**
   * @param query Nome do local
   * @returns Endereços correspondentes ao parâmetro informado
   */
  async getLocation(query: string) {
    try {
      const { data } = await toast.promise(
        axios.get(
          `https://nominatim.openstreetmap.org/?addressdetails=1&q=${query}&format=json&limit=5`
        ),
        {
          pending: i18n.t("httpResponses.searching.in") as string,
          success: {
            render() {
              return i18n.t("httpResponses.searching.sucess");
            },
            autoClose: 700,
          },
          error: i18n.t("httpResponses.searching.fail.general") as string,
        }
      );

      if (data.length === 0) {
        toast.error(i18n.t("httpResponses.searching.fail.nodata"), {
          autoClose: 5000,
        });
      }

      let functionOutput: Array<AddressProps> = [];
      data.forEach((element: AddressProps) => {
        let aux = {
          place_id: element.place_id,
          lat: element.lat,
          lon: element.lon,
          display_name: `${element.address?.road || ""}, ${
            element.address?.suburb || ""
          }, ${element.address?.city || ""}, ${element.address?.state || ""}`,
        };
        functionOutput.push(aux);
      });

      return functionOutput;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  /**
   * @param query Nome do local
   * @returns Endereços correspondentes ao parâmetro informado
   */
  async getLocationWithoutToast(
    query: string,
    latitude: number | undefined,
    longitude: number | undefined
  ) {
    const lang = navigator.language;
    try {
      const { data } = await api.get(
        `/findPlaces?query=${query}&lat=${latitude}&lng=${longitude}&lang=${lang}`
      );

      if (data.length === 0 && query.length != 0) {
        toast.error(i18n.t("httpResponses.searching.fail.nodata"), {
          autoClose: 2500,
        });
      }

      let functionOutput: Array<AddressProps> = [];

      data.forEach((element: GoogleAddressProps) => {
        let aux = {
          place_id: element.place_id,
          lat: element.geometry.location.lat,
          lon: element.geometry.location.lng,
          display_name: `${element.name} - ${
            element.formatted_address.split(",")[
              element.formatted_address.split(",").length - 3
            ]
          }`,
        };
        functionOutput.push(aux);
      });

      return functionOutput;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  /**
   * @param params Informações para rota personalizada (recurso assistivo, transport, origem, destino)
   * @returns GeoJSON com informações da rota
   */
  async getRoute(params: GetRouteProps) {
    try {
      const { data } = await toast.promise(
        api.get(
          `/router`,
          auth.getHeaders({
            params: {
              profile: params.assistive_resource,
              transport: params.transport,
              lonlats: `${params.from_cords}|${params.to_cords}`,
            },
          })
        ),
        {
          pending: i18n.t("httpResponses.getrouter.in") as string,
          success: {
            render() {
              return i18n.t("httpResponses.getrouter.sucess");
            },
            autoClose: 700,
          },
          error: i18n.t("httpResponses.getrouter.fail.general") as string,
        }
      );

      return data;
    } catch (error) {
      console.log(error);
      return {
        type: "FeatureCollection",
        features: [],
      };
    }
  }
}

export default new CheckPoints();

import { GeoJsonProperties } from "geojson";
import L from "leaflet";

import iconShadow from "leaflet/dist/images/marker-shadow.png";
import icon from "leaflet/dist/images/marker-icon.png";
class MapIcons {
  getIcon(code: string) {
    switch (code) {
      case "Faixa de Pedestre":
        return this.Crosswalk;

      case "Ponto de Ônibus":
        return this.Bus;

      case "Passarela":
        return this.Catwalk;

      case "Ascensores":
        return this.Elevator;

      case "Semáforos":
        return this.Semaphore;

      default:
        return this.Bus;
    }
  }

  Bus = ({ properties }: { properties: GeoJsonProperties }) => {
    const color = properties?.flagcolor || "#000";

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 14.6522 3.05359 17.1957 4.92896 19.0711C6.80432 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.071 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.071 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.80432 3.05357 4.92896 4.92893C3.05359 6.8043 2 9.34784 2 12Z"
          fill={"#fff"}
          fillOpacity="0.9"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 6.16669C9.66665 6.16669 7.33331 6.45835 7.33331 8.50002V14.0417C7.33331 14.5959 7.55498 15.0975 7.91665 15.465V16.6667C7.91665 16.9875 8.17915 17.25 8.49998 17.25H9.08331C9.40415 17.25 9.66665 16.9875 9.66665 16.6667V16.0834H14.3333V16.6667C14.3333 16.9875 14.5958 17.25 14.9166 17.25H15.5C15.8208 17.25 16.0833 16.9875 16.0833 16.6667V15.465C16.445 15.0975 16.6666 14.5959 16.6666 14.0417V8.50002C16.6666 6.45835 14.5783 6.16669 12 6.16669ZM12 7.33335C14.1641 7.33335 14.9925 7.60169 15.3075 7.91669H8.75081C9.10081 7.61335 9.94665 7.33335 12 7.33335ZM15.5 13.75C15.5 14.3917 14.975 14.9167 14.3333 14.9167H9.66665C9.02498 14.9167 8.49998 14.3917 8.49998 13.75V12H15.5V13.75ZM15.5 10.8334H8.49998V9.08335H15.5V10.8334Z"
          fill={"#000"}
        />
        <path
          d="M9.95831 14.3334C10.4416 14.3334 10.8333 13.9416 10.8333 13.4584C10.8333 12.9751 10.4416 12.5834 9.95831 12.5834C9.47506 12.5834 9.08331 12.9751 9.08331 13.4584C9.08331 13.9416 9.47506 14.3334 9.95831 14.3334Z"
          fill={"#000"}
        />
        <path
          d="M14.0416 14.3334C14.5249 14.3334 14.9166 13.9416 14.9166 13.4584C14.9166 12.9751 14.5249 12.5834 14.0416 12.5834C13.5584 12.5834 13.1666 12.9751 13.1666 13.4584C13.1666 13.9416 13.5584 14.3334 14.0416 14.3334Z"
          fill={"#000"}
        />
      </svg>
    );
  };

  Crosswalk = ({ properties }: { properties: GeoJsonProperties }) => {
    const color = properties?.flagcolor || "#000";

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 14.6522 3.05359 17.1957 4.92896 19.0711C6.80432 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.071 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.071 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.80432 3.05357 4.92896 4.92893C3.05359 6.8043 2 9.34784 2 12Z"
          fill={"#fff"}
          fillOpacity="0.9"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6192 14.0417V13.0208L13.1667 14.4792L14.6192 15.9375V14.9167H17.8334V14.0417H14.6192V14.0417ZM16.3809 16.5208H13.1667V17.3958H16.3809V18.4167L17.8334 16.9583L16.3809 15.5V16.5208V16.5208ZM10.5417 8.20833C11.1834 8.20833 11.7084 7.68333 11.7084 7.04167C11.7084 6.4 11.1834 5.875 10.5417 5.875C9.90002 5.875 9.37502 6.4 9.37502 7.04167C9.37502 7.68333 9.90002 8.20833 10.5417 8.20833ZM8.35419 10.1917L6.75002 18.4167H7.97502L8.99585 13.75L10.25 14.9167V18.4167H11.4167V14.0125L10.2209 12.8167L10.5709 11.0667C11.3292 12 12.4667 12.5833 13.75 12.5833V11.4167C12.6709 11.4167 11.7375 10.8333 11.2125 9.9875L10.6584 9.05417C10.4542 8.70417 10.075 8.5 9.66669 8.5C9.52085 8.5 9.37502 8.52917 9.22919 8.5875L6.16669 9.84167V12.5833H7.33335V10.6292L8.35419 10.1917Z"
          fill={"#000"}
        />
      </svg>
    );
  };

  Elevator = ({ properties }: { properties: GeoJsonProperties }) => {
    const color = properties?.flagcolor || "#000";

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 14.6522 3.05359 17.1957 4.92896 19.0711C6.80432 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.071 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.071 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.80432 3.05357 4.92896 4.92893C3.05359 6.8043 2 9.34784 2 12Z"
          fill={"#fff"}
          fillOpacity="0.9"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0833 7.91667V16.0833H7.91667V7.91667H16.0833ZM16.0833 6.75H7.91667C7.275 6.75 6.75 7.275 6.75 7.91667V16.0833C6.75 16.725 7.275 17.25 7.91667 17.25H16.0833C16.725 17.25 17.25 16.725 17.25 16.0833V7.91667C17.25 7.275 16.725 6.75 16.0833 6.75ZM10.8333 15.5V13.1667H11.4167V11.7083C11.4167 11.0667 10.8917 10.5417 10.25 10.5417H9.66667C9.025 10.5417 8.5 11.0667 8.5 11.7083V13.1667H9.08333V15.5H10.8333ZM9.95833 9.95833C10.3608 9.95833 10.6875 9.63167 10.6875 9.22917C10.6875 8.82667 10.3608 8.5 9.95833 8.5C9.55583 8.5 9.22917 8.82667 9.22917 9.22917C9.22917 9.63167 9.55583 9.95833 9.95833 9.95833ZM15.5 11.4167L14.0417 9.08333L12.5833 11.4167H15.5ZM12.5833 12.5833L14.0417 14.9167L15.5 12.5833H12.5833Z"
          fill={"#000"}
        />
      </svg>
    );
  };

  Catwalk = ({ properties }: { properties: GeoJsonProperties }) => {
    const color = properties?.flagcolor || "#000";

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 14.6522 3.05359 17.1957 4.92896 19.0711C6.80432 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.071 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.071 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.80432 3.05357 4.92896 4.92893C3.05359 6.8043 2 9.34784 2 12Z"
          fill={"#fff"}
          fillOpacity="0.9"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1666 7.91667V16.0833H10.8333V7.91667H13.1666ZM16.6666 6.75H15.5V7.91667H14.3333V6.75H9.66665V7.91667H8.49998V6.75H7.33331V17.25H8.49998V16.0833H9.66665V17.25H14.3333V16.0833H15.5V17.25H16.6666V6.75ZM14.3333 10.25V9.08333H15.5V10.25H14.3333ZM8.49998 10.25V9.08333H9.66665V10.25H8.49998ZM14.3333 12.5833V11.4167H15.5V12.5833H14.3333ZM8.49998 12.5833V11.4167H9.66665V12.5833H8.49998ZM14.3333 14.9167V13.75H15.5V14.9167H14.3333ZM8.49998 14.9167V13.75H9.66665V14.9167H8.49998Z"
          fill={"#000"}
        />
      </svg>
    );
  };

  Semaphore = ({ properties }: { properties: GeoJsonProperties }) => {
    const color = properties?.flagcolor || "#000";

    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 14.6522 3.05359 17.1957 4.92896 19.0711C6.80432 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.071 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.071 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.80432 3.05357 4.92896 4.92893C3.05359 6.8043 2 9.34784 2 12Z"
          fill={"#fff"}
          fillOpacity="0.9"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.6666 10.8333H14.9166V10.1683C15.92 9.90583 16.6666 9.00167 16.6666 7.91667H14.9166V7.33333C14.9166 7.0125 14.6541 6.75 14.3333 6.75H9.66665C9.34581 6.75 9.08331 7.0125 9.08331 7.33333V7.91667H7.33331C7.33331 9.00167 8.07998 9.90583 9.08331 10.1683V10.8333H7.33331C7.33331 11.9183 8.07998 12.8225 9.08331 13.085V13.75H7.33331C7.33331 14.835 8.07998 15.7392 9.08331 16.0017V16.6667C9.08331 16.9875 9.34581 17.25 9.66665 17.25H14.3333C14.6541 17.25 14.9166 16.9875 14.9166 16.6667V16.0017C15.92 15.7392 16.6666 14.835 16.6666 13.75H14.9166V13.085C15.92 12.8225 16.6666 11.9183 16.6666 10.8333ZM13.75 16.0833H10.25V7.91667H13.75V16.0833ZM12 15.5C12.4841 15.5 12.875 15.1092 12.875 14.625C12.875 14.1408 12.4841 13.75 12 13.75C11.5158 13.75 11.125 14.1408 11.125 14.625C11.125 15.1092 11.5158 15.5 12 15.5ZM12 12.875C12.4841 12.875 12.875 12.4842 12.875 12C12.875 11.5158 12.4841 11.125 12 11.125C11.5158 11.125 11.125 11.5158 11.125 12C11.125 12.4842 11.5158 12.875 12 12.875ZM12 10.25C12.4841 10.25 12.875 9.85917 12.875 9.375C12.875 8.89083 12.4841 8.5 12 8.5C11.5158 8.5 11.125 8.89083 11.125 9.375C11.125 9.85917 11.5158 10.25 12 10.25Z"
          fill={"#000"}
        />
      </svg>
    );
  };

  NearToMe = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1707 1707"
        height="24px"
        width="24px"
      >
        <g id="Layer_x0020_1">
          <path
            fill="#ffffff"
            d="M770 1308l83 128 241 -374c3,-3 5,-6 9,-8 176,-93 286,-274 286,-473 0,-295 -241,-536 -536,-536 -295,0 -535,241 -535,536 0,199 110,380 286,473 3,2 6,5 8,8l158 246zm83 -464c-145,0 -263,-118 -263,-263 0,-145 118,-263 263,-263 145,0 263,118 263,263 0,145 -118,263 -263,263z"
          />
          <path
            fill="#ffffff"
            d="M966 1345c-11,18 -92,149 -101,152 -10,6 -23,6 -31,-7l-94 -145c-159,21 -274,85 -274,154 0,79 156,162 387,162 232,0 388,-83 388,-162 0,-69 -115,-133 -275,-154z"
          />
        </g>
      </svg>
    );
  };

  BlueMarker = () =>
    new L.Icon({
      iconUrl: icon,
      shadowUrl: iconShadow,

      iconAnchor: [12.5, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [12.5, 41], // the same for the shadow
    });
}

export default new MapIcons();

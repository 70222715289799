import { ChatbotProps } from "../../../config/interfaces";
import { i18n } from "../../../translate/i18n";
import { Input, InputBox } from "./styles";

const Options = (props: ChatbotProps) => {
    return <>
        <InputBox>
            <Input
                onClick={() => {
                    props.actionProvider.handleReset();
                }}
                type="button"
                value={i18n.t('chatbot.widgets.generateRoute.anotherRoute') as string}
            />
            <Input
                onClick={() => {
                    console.log("Segunda Opção!");
                }}
                type="button"
                value={i18n.t('chatbot.widgets.generateRoute.anotherOption') as string}
            />
        </InputBox>
    </>;
};

export default Options;
import {
  PointsLegend,
  ChatBotIcon,
} from "./AppComponents/components/mapWidgets/widget";
import { useRef, useState, useEffect } from "react";
import { Bar } from "./AppComponents/bar/Bar";
import Map from "./AppComponents/map/Map";
import { GeoJSON, Map as MapType } from "leaflet";
import { MapStateProps } from "./config/interfaces";
import MapStateControl from "./mapStateControl/MapStateControl";
import ChatbotWindow from "./chatbot/window";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";

import ReactGA from "react-ga4";
import { i18n } from "./translate/i18n";

import "./styles.css";
import { tourConfig } from "./config/tourConfig";
import auth from "./controllers/user/auth";

const TRACKING_ID = "G-2DH5NXKBE2";
ReactGA.initialize(TRACKING_ID);

function App() {
  const [mapState, setmapState] = useState<MapStateProps>({});
  const routerLayer = useRef<GeoJSON | null>(null);
  const mapRef = useRef<MapType | null>(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNps, setShowNps] = useState<boolean>(false);

  const MapControl = new MapStateControl(
    mapState,
    setmapState,
    routerLayer,
    mapRef
  );

  //Controla a exibição da janela do chatbot
  const [showChatWindow, setShowChatWindow] = useState(false);
  function handleShowChatWindow() {
    setShowChatWindow(!showChatWindow);
  }

  const disableBody = (target: any) => disableBodyScroll(target);
  const enableBody = (target: any) => enableBodyScroll(target);

  useEffect(() => {
    setIsTourOpen(!auth.getDataStorage());
  }, []);

  return (
    <div className="App">
      <Map
        mapState={mapState}
        setmapState={setmapState}
        routerLayer={routerLayer}
        mapRef={mapRef}
      />
      <Bar
        addRouteMakers={MapControl.addRouteMakers}
        mapState={mapState}
        resetMap={MapControl.resetMap}
        setRouter={MapControl.setRouter}
        setShowNps={setShowNps}
        showNps={showNps}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <Tour
        lastStepNextButton={
          <svg
            onClick={() => {
              setIsTourOpen(false);
            }}
            viewBox="0 0 18.4 14.4"
            aria-labelledby="NextButtonTitleUniqueID NextButtonDescUniqueID"
          >
            <title id="NextButtonTitleUniqueID">
              {i18n.t("consts.tourConfig.nextButton")}
            </title>
            <desc id="NextButtonDescUniqueID">
              {i18n.t("consts.tourConfig.nextButton")}
            </desc>
            <path
              d="M17 7.2H1M10.8 1L17 7.2l-6.2 6.2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
            ></path>
          </svg>
        }
        onRequestClose={() => {
          setIsTourOpen(false);
        }}
        steps={tourConfig()}
        isOpen={isTourOpen}
        maskClassName="mask"
        className="helper"
        rounded={10}
        accentColor={"#FFEA00"}
        onAfterOpen={disableBody}
        onBeforeClose={enableBody}
        showNumber={false}
        prevButton={
          <svg
            viewBox="0 0 18.4 14.4"
            aria-labelledby="LastButtonTitleUniqueID LastButtonDescUniqueID"
            role="img"
          >
            <title id="LastButtonTitleUniqueID">
              {i18n.t("consts.tourConfig.prevButton")}
            </title>
            <desc id="LastButtonDescUniqueID">
              {i18n.t("consts.tourConfig.prevButton")}
            </desc>
            <path
              d="M1.4 7.2h16M7.6 1L1.4 7.2l6.2 6.2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
            ></path>
          </svg>
        }
        nextButton={
          <svg
            viewBox="0 0 18.4 14.4"
            aria-labelledby="NextButtonTitleUniqueID NextButtonDescUniqueID"
          >
            <title id="NextButtonTitleUniqueID">
              {i18n.t("consts.tourConfig.nextButton")}
            </title>
            <desc id="NextButtonDescUniqueID">
              {i18n.t("consts.tourConfig.nextButton")}
            </desc>
            <path
              d="M17 7.2H1M10.8 1L17 7.2l-6.2 6.2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
            ></path>
          </svg>
        }
        getCurrentStep={function (curr) {
          const maskHighlight = document.getElementById("mask-main");
          if (maskHighlight) {
            maskHighlight.classList.remove("chatbot-highlight");
            if (curr == 7) {
              maskHighlight.classList.add("chatbot-highlight");
            }
          }
        }}
      />
      <PointsLegend />
      <ChatBotIcon
        data-tut="reactour__chatbot"
        onClick={() => handleShowChatWindow()}
      >
        <img
          src="/images/bot_profile_call_center.jpg"
          alt="Ícone que abre a janela do chatbot"
        />
        <div id="description">{i18n.t("chatbot.getAccess")}</div>
      </ChatBotIcon>
      <ChatbotWindow
        handleDisplay={showChatWindow}
        handleClose={handleShowChatWindow}
        mapControl={MapControl}
      />
    </div>
  );
}

export default App;

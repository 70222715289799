import styled from "styled-components";

const ButtonContainer = styled.button<{
  $color: string;
  $backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 190px;
  width: fit-content;
  padding: 13px 10px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-family: "DM Sans", sans-serif;
  cursor: pointer;

  color: ${(props) => props.$color};
  background-color: ${(props) => props.$backgroundColor};

  :hover {
    opacity: 0.8;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    padding: 3px;
    border-radius: 50%;
    background-color: #ffffff;
  }
`;

export { ButtonContainer };

import axios from "axios";
//url local
// const API_URL = "http://localhost:3333";

//url de producao
const API_URL = "https://izzistrit-backend.azurewebsites.net";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export default api;
